/**
 * 【最新水闸排队情况】
 */
import React, { useEffect, useState } from 'react';
import Container from '@/components/container';
// import Tab from '@/components/tab';
import styles from './index.module.scss';
import Table from '@/components/table_component';
import ScrollTab from '@/components/tab/scroll_tab';

const Index = (props) => {
  const [upperData, setUpperData] = useState([]);
  const [lowerData, setLowerData] = useState([]);
  useEffect(() => {
    setLowerData(
      props.dataSource.length !== 0
        ? props.dataSource[props.activeTab].lower
        : []
    );
    setUpperData(
      props.dataSource.length !== 0
        ? props.dataSource[props.activeTab].upper
        : []
    );
  }, [props.activeTab, props.dataSource]);
  return (
    <div className={styles.queueUP}>
      <Container
        text="最新水闸排队情况"
        showIsMore={true}
        className={styles.bgc}
        hasTitle={true}
      >
        {/* <Tab {...props} /> */}
        <ScrollTab tabs={props.tabList} activeTab={props.activeTab} />
        <div className={styles.tableWrapper} ref={props.wrapperRef}>
          <div
            className={styles.listWrapper}
            ref={props.listRef}
            style={{ top: `${props.topDistance / 192}rem` }}
          >
            <h6>上游报道队列</h6>
            <Table {...props} dataSource={upperData} />
            <h6>下游报道队列</h6>
            <Table {...props} dataSource={lowerData} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Index;
