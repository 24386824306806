/**
 * 【页面头部共用-title】
 * @param {props} object
 * @param {title} string 大标题
 * @param {weater} object  天气具体参数可以根据具体接口返回值调整
 * @param {rightText} string 右边文案
 * <TitleComponent
        title="云砼网数据大屏"
        rightText="智慧矿山大屏"
      />
 */
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import Weater from './weater';
import TitleRight from './title_right';
import * as Api from '../../services/mine.js';
import TitleLogo from './title'
import { useHistory } from 'react-router-dom';

const TitleComponent = (props) => {
  let history = useHistory();
  const [waeterData, setWeaterData] = useState({});
  useEffect(async () => {
    const res = await Api.getWeaterData();
    if (res.status === '1') {
      const { forecasts } = res;
      setWeaterData(forecasts);
    }
  }, []);

  const goToNextPage = (rightText) => {
    if (rightText === '智慧矿山大屏') {
      history.push('/mine');
    } else {
      history.push('/home');
    }
  };

  return (
    <div className={styles.titleWrapper}>
      <TitleLogo />
      <h1>{props.title}</h1>
      <Weater data={waeterData} />
      <TitleRight {...props} goToNextPage={goToNextPage} />
    </div>
  );
};

export default TitleComponent;
