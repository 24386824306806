/**
 * 地磅数据
 */
import Mock from 'mockjs';
/**
 * 当日逐时数据
 * @param {weight} 逐时出场车辆运砂量（吨）
 * @param {count} 车辆
 * 数据生成规则：
 * 逐时出场车辆运砂量（吨）：18:00-8:00  为0   8:00-18:00 为5000-7000随机数
 * 逐时出场车辆（辆）：[出场车辆运砂量]/40后取整数
 */
const weighbridgeList = new Array(24).fill('').map((item, index) => {
  if (index > 7 && index < 19) {
    return Mock.mock({
      'weight|5000-7000': 5000,
      date: index,
    });
  } else {
    return {
      weight: 0,
      date: index,
    };
  }
});
const weighbridgeData = weighbridgeList.map((item) => ({
  ...item,
  count: parseInt(item.weight / 40),
}));

const weighbridgeX = weighbridgeData.map((item) => item.date);
const weighbridgeY = [
  weighbridgeData.map((item) => item.weight),
  weighbridgeData.map((item) => item.count),
];

/**
 * 近一个月的地磅数据
 * @param {weight} 逐时出场车辆运砂量（吨）
 * @param {count} 车辆
 * 数据生成规则：
 * weight：等于当日逐时出场车辆运砂量之和
 * count：等于当日逐时出场车辆之和
 * 8:00-18:00 为11个小时
 * 每日的数据是逐时数据*11
 * 每日车辆是逐时车辆*11
 */
const weighbridgeMonthList = new Array(31).fill('').map((item, index) => {
  return Mock.mock({
    'weight|5000-7000': 5000,
    date: index + 1,
  });
});
const weighbridgeMonthData = weighbridgeMonthList.map((item) => ({
  ...item,
  weight: item.weight * 11,
  count: parseInt(item.weight / 40) * 11,
}));

const weighbridgeMonthDataX = weighbridgeMonthData
  .map((item) => item.date)
  .filter((item, index) => index % 3 === 0);
const weighbridgeMonthDataY = {
  weight: weighbridgeMonthData
    .map((item) => item.weight/10000)
    .filter((item, index) => index % 3 === 0),
  count: weighbridgeMonthData
    .map((item) => item.count/10000)
    .filter((item, index) => index % 3 === 0),
};

const weighbridgeMonthDataList = {
  weight: weighbridgeMonthData.map((item) => item.weight),
  count: weighbridgeMonthData.map((item) => item.count),
};

export default {
  weighbridgeData: {
    x: weighbridgeX,
    y: weighbridgeY,
  },
  weighbridgeMonthData: {
    x: weighbridgeMonthDataX,
    y: weighbridgeMonthDataY,
  },
  weighbridgeMonthDataTotal: weighbridgeMonthDataList,
};
