/**
 * 环境监测-数据
 */
import Mock from 'mockjs';

/**
 * 排水水质
 * @param {ss} 悬浮物ss
 * @param {PH} PH值
 * @param {date} 时间
 * 数据sc规则：
 * 悬浮物ss：50-100之间
 * PH值：6-9之间
 */
const waterQuality = {
  x: new Array(12).fill('').map((item, index) =>
    Object.values(
      Mock.mock({
        date: index * 2 < 10 ? `0${index * 2}:00` : `${index * 2}:00`,
      })
    ).reduce(function (a, b) {
      return a.concat(b);
    })
  ),
  ss: new Array(12).fill('').map((item, index) =>
    Object.values(
      Mock.mock({
        'ss|50-100': 50,
      })
    ).reduce(function (a, b) {
      return a.concat(b);
    })
  ),
  ph: new Array(12).fill('').map((item, index) =>
    Object.values(
      Mock.mock({
        'pH|6-9': 6,
      })
    ).reduce(function (a, b) {
      return a.concat(b);
    })
  ),
};

//   new Array(24).fill('').map((item, index) =>({
//   x:Mock.mock({
//     date: index,
//   }),
//   ss:Mock.mock({
//     'ss|50-100': 50,
//   }),
//   ph:Mock.mock({
//     'pH|6-9': 6,
//   }),
// })

// );

/**
 * 噪声
 * @param {dB} 分贝
 * @param {date} 时间
 * 数据生成规则：
 * 18:00~ 8:00  分贝25-40之间
 * 8:00~ 18:00  分贝50-70之间
 */

const noiseList = new Array(12).fill('').map((item, index) => {
  if (index > 4 && index < 9) {
    return Mock.mock({
      'dB|50-70': 50,
      date: index * 2 < 10 ? `0${index * 2}:00` : `${index * 2}:00`,
    });
  } else {
    return Mock.mock({
      'dB|25-40': 25,
      date: index * 2 < 10 ? `0${index * 2}:00` : `${index * 2}:00`,
    });
  }
});

const noiseX = noiseList.map((item, index) => item.date);
const noiseY = noiseList.map((item, index) => item.dB);
const noise = { x: noiseX, y: noiseY };
/**
 * 空气质量
 * 数据：提供指定数据
 * @param {PM} PM2.5
 * @param {PM10} PM10
 * @param {date} 时间
 */
const airQualityList = [
  { date: '0:00', PM: 13, PM10: 17 },
  { date: '1:00', PM: 13, PM10: 17 },
  { date: '2:00', PM: 11, PM10: 19 },
  { date: '3:00', PM: 8, PM10: 17 },
  { date: '4:00', PM: 10, PM10: 15 },
  { date: '5:00', PM: 8, PM10: 13 },
  { date: '6:00', PM: 12, PM10: 15 },
  { date: '7:00', PM: 11, PM10: 17 },
  { date: '8:00', PM: 15, PM10: 19 },
  { date: '9:00', PM: 17, PM10: 23 },
  { date: '10:00', PM: 24, PM10: 28 },
  { date: '11:00', PM: 25, PM10: 29 },
  { date: '12:00', PM: 24, PM10: 35 },
  { date: '13:00', PM: 25, PM10: 37 },
  { date: '14:00', PM: 25, PM10: 36 },
  { date: '15:00', PM: 27, PM10: 37 },
  { date: '16:00', PM: 26, PM10: 37 },
  { date: '17:00', PM: 30, PM10: 38 },
  { date: '18:00', PM: 27, PM10: 37 },
  { date: '19:00', PM: 25, PM10: 35 },
  { date: '20:00', PM: 23, PM10: 34 },
  { date: '21:00', PM: 22, PM10: 30 },
  { date: '22:00', PM: 20, PM10: 28 },
  { date: '23:00', PM: 18, PM10: 29 },
];
// 展示数据为间隔1小时
const airQualityFilter = airQualityList.filter((item,index) => {
  return index % 2===0
});
const airQualityPM = airQualityFilter.map((item) => item.PM);
const airQualityPM10 = airQualityFilter.map((item) => item.PM10);
const airQualityX = airQualityFilter.map((item) => item.date);

export default {
  waterQuality: waterQuality,
  noise: noise,
  airQuality: {
    x: airQualityX,
    y: [[...airQualityPM], [...airQualityPM10]],
  },
};
