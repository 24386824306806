/**
 * 【最新水闸排队情况】
 */
import React, { useEffect, useState, useRef } from 'react';
import useInterval from '@/hooks/useInterval';
import QueueUp from './index';
import { dpr } from '@/utils';
import { getChuanzha } from '../../../../services/mine.js';
const columns = [
  {
    title: '排队号',
    dataIndex: 'no',
    key: 'no',
    width: 90,
  },
  {
    title: '船舶名称',
    dataIndex: 'shipName',
    key: 'shipName',
    width: 120,
  },
  {
    title: '总吨',
    dataIndex: 'tonnage',
    key: 'tonnage',
    width: 80,
  },
  {
    title: '货物',
    dataIndex: 'goods',
    key: 'goods',
    width: 90,
  },
  {
    title: '吃水',
    dataIndex: 'draught',
    key: 'draught',
    width: 60,
  },
  {
    title: '报道时间',
    dataIndex: 'time',
    key: 'time',
    width: 100,
  },
];

let num = 0;
let top = 0;
const Index = (props) => {
  const wrapperRef = useRef();
  const listRef = useRef();
  const [active, setActive] = useState(0);
  const [topDistance, setTopDistance] = useState(0);
  const [tabList, setTabList] = useState([]);
  const [tableList, setTablest] = useState([]);

  useEffect(() => {
    getData();
  }, []);
  // 获取排队情况数据
  const getData = async () => {
    const res = Object.values(await getChuanzha());
    const tabs = res.map((item) => ({ title: item.name }));

    setTabList(tabs);
    setTablest(res);
  };

  // 列表滚动定时器
  useInterval(() => {
    if (
      listRef.current.clientHeight / dpr + top ===
      wrapperRef.current.clientHeight / dpr ||
      listRef.current.clientHeight / dpr + top <
      wrapperRef.current.clientHeight / dpr
    ) {
      top = 0;
      setTopDistance(0);

      if (num === tableList.length - 1) {
        num = 0;
      } else {
        num = num + 1;
      }
      setActive(num);
      return;
    }
    top = top - 1;
    setTopDistance(top);
  }, 30);
  return (
    <QueueUp
      columns={columns}
      dataSource={tableList || []}
      // onChange={onChange}
      tabList={tabList}
      activeTab={active}
      wrapperRef={wrapperRef}
      listRef={listRef}
      topDistance={topDistance}
    />
  );
};

export default Index;
