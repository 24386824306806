/**
 * 【柱状图控件】
 * @param { id }  String 图标的id必传
 * @param { type }  String 柱状图类型 可接受的值(single,double)
 * @param { legendPosition } String 示例显示位置可接受的值(left,right)
 * @param { xAxisData } Array x轴坐标刻度标签内容
 * @param { hasYAxis } Bool 是否在y轴显示坐标轴名称
 * @param { yAxisName } String|Array 是否在y轴显示坐标轴名称
 * @param { legendData } Array 示例数据
 * @param { seriesData } Array 用于展示的图表数据
 */
import React, { useState, useEffect } from 'react';
import * as echarts from 'echarts/lib/echarts';
import styles from './style/bar_graph.module.scss';
import { fontSize } from '@/utils/utils';
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/grid';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/markPoint';
import { linePolygonIntersect } from 'echarts/lib/util/graphic';
export default function BarGraph(props) {
  const [singleOption] = useState({
    grid: {
      top: fontSize(80),
    },
    legend: {
      top: fontSize(31),
      right: 0,
      data: [
        {
          name: '组骨料',
          icon: 'circle',
        },
        {
          name: '机制砂',
          icon: 'circle',
        },
        {
          name: '天然砂',
          icon: 'circle',
        },
      ],
      textStyle: {
        color: 'rgba(255,255,255,.65)',
        fontSize: fontSize(12),
        fontFamily: 'CN-Regular',
        fontWeight: 400,
      },
    },
    xAxis: {
      data: ['一月', '二月', '三月', '四月', '五月'],
      // axisTick: false,
      axisTick: {
        show: true,
        alignWithLabel: true,
      },
      axisLabel: {
        color: 'rgba(255,255,255,.65)',
        fontSize: fontSize(15),
        fontFamily: 'CN-Regular',
        fontWeight: 400,
      },
    },
    yAxis: {
      name: '价格（元/吨）',
      nameTextStyle: {
        color: 'rgba(255,255,255,.65)',
        fontSize: fontSize(13),
        fontFamily: 'CN-Regular',
        fontWeight: 400,
        padding: [0, 0, fontSize(20), fontSize(25)],
      },
      axisLine: false,
      axisTick: false,
      axisLabel: {
        color: 'rgba(255,255,255,.65)',
        fontSize: fontSize(12),
        fontFamily: 'CN-Regular',
        fontWeight: 400,
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(255,255,255,0.15)',
        },
      },
    },
    series: [
      {
        name: '组骨料',
        type: 'bar',
        data: [5, 20, 36, 10, 10, 20, 4],
        itemStyle: {
          color: 'rgba(170,98,21,1)',
        },
        barWidth: fontSize(15),
      },
      // {
      //   name: "机制砂",
      //   type: "bar",
      //   // data: [],
      //   itemStyle: {
      //     color: "rgba(54,207,201,1)",
      //   },
      //   barWidth: fontSize(15),
      // },
      // {
      //   name: "天然砂",
      //   type: "bar",
      //   // data: [],
      //   itemStyle: {
      //     color: "rgba(243,204,98,1)",
      //   },
      //   barWidth: fontSize(15),
      // },
    ],
    animation: true, // 动态数据接口是否开启动画效果
    animationDuration: 2000,
  });
  const [doubleOption] = useState({
    legend: {
      top: fontSize(5),
      right: 0,
      orient: 'horizontal',
      x: 'center',
      y: ' center',
      data: [
        {
          name: '悬浮物SS',
          icon: 'circle',
        },
        {
          name: '酸碱度PH',
          icon: 'circle',
        },
      ],
      textStyle: {
        color: 'rgba(255,255,255,.65)',
        fontSize: fontSize(15),
        fontFamily: 'CN-Regular',
        fontWeight: 400,
        padding: [0, fontSize(0), fontSize(0), 0],
      },
    },
    xAxis: {
      data: [
        '0:00',
        '2:00',
        '4:00',
        '6:00',
        '8:00',
        '10:00',
        '12:00',
        '14:00',
        '16:00',
        '18:00',
      ],
      axisTick: {
        show: true,
        alignWithLabel: true,
      },
      axisLabel: {
        color: 'rgba(255,255,255,.65)',
        fontSize: fontSize(15),
        fontFamily: 'CN-Regular',
        fontWeight: 400,
      },
    },
    yAxis: [
      {
        // name: "价格（元/吨）",
        nameTextStyle: {
          color: 'rgba(255,255,255,.65)',
          fontSize: fontSize(13),
          fontFamily: 'CN-Regular',
          fontWeight: 400,
          padding: [0, 0, 10, -fontSize(20)],
        },
        axisLine: false,
        axisTick: false,
        axisLabel: {
          color: 'rgba(255,255,255,.65)',
          fontSize: fontSize(15),
          fontFamily: 'CN-Regular',
          fontWeight: 400,
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(255,255,255,0.15)',
          },
        },
        // min:10000,
        // max:1,
        splitNumber: 5,
        // interval:(10000-1)/5,
      },
      {
        nameTextStyle: {
          color: 'rgba(255,255,255,.65)',
          fontSize: fontSize(13),
          fontFamily: 'CN-Regular',
          fontWeight: 400,
          padding: [0, 0, 0, 25],
        },
        axisLine: false,
        axisTick: false,
        axisLabel: {
          color: 'rgba(255,255,255,.65)',
          fontSize: fontSize(15),
          fontFamily: 'CN-Regular',
          fontWeight: 400,
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(255,255,255,0.15)',
          },
        },
        // min:0,
        // max:1,
        splitNumber: 5,
        // interval:(1-0)/5,
      },
    ],
    series: [
      {
        // name: '悬浮物SS',
        name: '',
        yAxisIndex: 1,
        type: 'line',
        smooth: 0.25,
        data: [5, 20, 36, 10, 10, 20, 4, 10, 10, 20],
        lineStyle: {
          color: 'rgba(243,204,98,1)',
        },
        itemStyle: {
          color: 'rgba(243,204,98,1)',
          // opacity: 0,
        },
      },
      {
        // name: '酸碱度PH',
        name: '',
        type: 'bar',
        data: [5, 20, 36, 10, 10, 20, 4, 10, 10, 20],
        itemStyle: {
          color: 'rgba(170,98,21,1)',
        },
        barWidth: fontSize(15),
      },
    ],
    animation: true,
    animationDuration: 2000,
  });
  useEffect(() => {
    props.type === 'single'
      ? initOption(singleOption)
      : initOption(doubleOption);
  }, []);
  const initOption = (data) => {
    data.xAxis.data = props.xAxisData ? props.xAxisData : data.xAxis.data;
    props.legendPosition === 'left'
      ? (data.legend.left = 0)
      : (data.legend.right = 0);
    data.xAxis.data = props.xAxisData ? props.xAxisData : data.xAxis.data;
    if (props.hasYAxis) {
      if (typeof props.yAxisName === 'string' && props.type === 'single') {
        data.yAxis.name = props.yAxisName;
      } else if (typeof props.yAxisName === 'object') {
        props.yAxisName.forEach((item, index) => {
          data.yAxis[index].name = item;
        });
      }
    } else {
      data.yAxis.name = '';
    }
    if (props.legendData) {
      data.legend.data = props.legendData.map((item, index) => {
        return {
          icon: 'circle',
          name: item,
        };
      });
      // data.legend.data = props.legendData.map((item, index) => {
      //   data.series[index].name = item;
      //   return {
      //     icon: 'circle',
      //     name: item,
      //   };
      // });
    }
    if (props.seriesData && props.type === 'single') {
      /**
       * 为了柱状图共用，data.series数据不可写死
       * 需要根据数据自动生成，颜色需要支持传入
       */
      data.series = props.seriesData.map((item, index) => ({
        ...data.series[0],
        data: item,
        name: props.legendData ? props.legendData[index] : [],
        itemStyle: {
          color: props.seriesColor
            ? props.seriesColor[index]
            : 'rgba(170,98,21,1)',
        },
      }));
    } else if (props.seriesData) {
      // 【柱状图】+ 【折线】还是走已经设定的数据格式
      props.seriesData.forEach((item, index) => {

        data.series[index].data = item;
        if (props.yAxisMinOrMax) {
          data.yAxis[index] = {
            ...data.yAxis[index],
            ...props.yAxisMinOrMax[index]

          };
        }
      });
      data.legend.data = props.legendData.map((item, index) => {
        data.series[index].name = item;
        return {
          icon: 'circle',
          name: item,
        };
      });
    }
    // document.getElementsByClassName("amap-marker")[0].getBoundingClientRect();
    let myChart = echarts.init(document.getElementById(props.id));
    myChart.clear();
    myChart.setOption(data);
    myChart.resize();
  };
  return (
    <div
      className={`${styles.barGraph} ${props.className ? props.className : ''}`}
    >
      <div id={props.id} style={{ width: '100%', height: '100%' }}></div>
    </div>
  );
}
