/**
 * 【指数模块-小数据模块】
 * @param {props} object
 * 接口返回格式：{"date":"2020/12/08","name":"水泥指数","zd":"+0.08","zdf":"+0.05%","zs":"155.04"}
 */
import React from 'react'
import styles from './index.module.scss'
import icon from '@/assets/images/icon/icon_triangle.png'

const Index = (props) => {
  const { data } = props
  return (
    <div className={styles.exponentWrapper}>
      <h3>
        <span>{data.name}</span>
      </h3>
      <span className={styles.date}>{data.date}</span>
      <div className={styles.data}>
        <p>{data.zs}</p>
        <div
          className={styles.icon}
          style={{
            transform: `${data.zd > 0 ? '' : 'rotate(180deg)'}`
          }}
        >
          <img src={icon} alt="" />
        </div>
      </div>
      <div className={styles.percentage}>
        <span>{data.zd}</span>
        <span>{data.zdf}</span>
      </div>
    </div>
  )
}

export default Index
