/**
 * 【数据统计-数字+文字描述】
 * @param {props} object
 * @param { dataList } array
 */
import React, {useState, useEffect} from 'react';
import styles from './index.module.scss'
import DataItem from './data_item'
const Index = (props) => {
  const [dataList, setDataList ] = useState([])
  useEffect(() => {
    setDataList(props.dataList || [])
  }, [props])
  return (
    <div className={styles.dataStatistics} >
      {
        dataList.map((item, index) =>(
          <DataItem key={index} item={item} />
        ))
      }
    </div>
  );
};

export default Index;