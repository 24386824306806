/**
 * 【视频轮询播放-逻辑】
 */
import React, { useRef, useEffect, useState, useContext } from 'react'
import Video from './index'
import { IndexContext } from '../../index'

import useInterval from '@/hooks/useInterval'
let num = 0
const VideoPlay = (props) => {
  const [loadingBtnStatus, setLoadingBtnStatus] = useState(true);
  const videoRef = useRef()
  const [duration, setDuration] = useState(1000)
  // const [num, setNum] = useState(0);
  // console.log(444,useContext(IndexContext))
  let { videoList } = useContext(IndexContext)
  // console.log(555,videoList,videoList.length)
  const [currentUrl, setCurrentUrl] = useState()
  useEffect(() => {
    if(videoList.length>0){
      videoRef.current.addEventListener('ended', () => {
        setLoadingBtnStatus(true)
        console.log(num,videoList.length)
        if (num === videoList.length - 1) {
          // setNum(0);
          num = 0

        } else {
          num = num + 1
        }
        console.log(num)
      })
    }


    videoRef.current.addEventListener('canplay', () => {
      console.log('可以播放')
      setLoadingBtnStatus(false)
    })
    // videoRef.current.addEventListener('loadstart', (e) => {
    //   console.log('开始加载')
    //   setLoadingBtnStatus(true)
    // })
    // setCurrentUrl(videoList[0])
  }, [videoList])

  useEffect(() => {
    videoRef.current.addEventListener('playing', () => {
      setDuration(videoRef.current.duration)
    })
  }, [currentUrl])


  useEffect(() => {
    setCurrentUrl(videoList[num])
  }, [num,videoList])

  /*useInterval(() => {

    if (num === videoList.length - 1) {
      // setNum(0);
      num = 0
      return false
    } else {
      num = num + 1
      // setNum(num + 1);
    }

    // setCurrentUrl(videoList[num])
  }, duration * 1000)*/

  return (
    <Video
      {...props}
      videoRef={videoRef}
      videoUrl={currentUrl}
      urlList={videoList}
      num={num}
      duration={duration}
      loadingBtnStatus={loadingBtnStatus}
    />
  )
}

export default VideoPlay
