import axios from 'axios';

const request = (url, options) => {
  const instance = axios.create();
  instance.interceptors.response.use(
    ({ data, config, headers }) => {
      if (headers['content-type'] === 'application/octet-stream') {
        return { data, headers, config };
      }
      const success = data.msg === 'success';
      if(success){
        return Promise.resolve({
          ...data,
          success,
        });
      }
      return Promise.resolve({
        ...data,
      });
    },
    (error) => {
      if (!error.response) {
        return Promise.reject(error);
      }
      const { response } = error;
      const { status, config } = response;
      // notification.error({
      //   message: `请求错误 ${status}: ${config.url}`,
      //   description: errorText,
      // });  

      return Promise.reject(error);
    }
  );

  return instance.request({
    method: 'POST',
    url,
    ...options,
  });
};

export default request;
