import request from '@/utils/request';
const baseUrl = process.env.BASE_URL
console.log(baseUrl)
// 高德天气接口
export const getWeaterData = () => {
  return request('https://restapi.amap.com/v3/weather/weatherInfo', {
    method: 'GET',
    params: {
      key: '971a8fb864be8fa5148d600250aab949', // 非客户提供
      city: '450400',
      extensions: 'all',
    },
  });
};

// 查询水泥指数····
export const getExponent = () => {
  return request(baseUrl + '/ebp/quotations/zhishu', {
    method: 'GET',
  });
};

// 最新水情
export const getWeaterCondition = () => {
  return request(baseUrl + '/ebp/quotations/shuiqing', {
    method: 'GET',
  });
};

// 获取砂石的价格走势
export const getPriceTrend = () => {
  return request(baseUrl + '/ebp/quotations/prices', {
    method: 'GET',
  });
};
// 获取最新水闸排队情况
export const getChuanzha = (code) => {
  return request(baseUrl + '/ebp/quotations/chuanzha', {
    method: 'GET',
  });
};

/**
 * 丫丫天气-未来24小时天气查询（需要付费），试用9天，2021-01-4开始
 * http://www.yytianqi.com/home
 */
export const getQweather24h = (code) => {
  return request('https://static.test.highlands.ltd/api/weather/weatherhours', {
    method: 'GET',
    params: {
      city: 'CH300601',
      key: 'c0v3j1ut0qnnqp5h',
    },
  });
};

/**
 * 数据统计
 */
export const getDataStatistics = (code) => {
  return request(
    baseUrl + '/cip/indexStatistics/getIndexStatistics',
    {
      method: 'GET',
    }
  );
};

/**
 * 最新动态
 */
export const getIndexDelivery = (code) => {
  return request(
    baseUrl + '/cip/indexStatistics/getIndexDelivery',
    {
      method: 'GET',
      params: {
        page:1,
        rows:100000
      }
    }
  );
};
/**
 * 矿山实时采矿视频
 */
export const getSalVideo = () => {
  return request(
    baseUrl + '/cip/indexStatistics/getSalVideos',
    {
      method: 'GET',
    }
  );
};
/**
 * 船舶实时运输动态
 */
export const getWayBill = () => {
  return request(
    baseUrl + '/cip/indexStatistics/getWayBill',
    {
      method: 'GET',
    }
  );
};
