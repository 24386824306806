/**
 * 【视频播放-逻辑处理】
 */
import React,{useRef, useState, useEffect} from 'react';
import VideoComponent from './index'

const VideoPlay = (props) => {
  const [playBtnStatus, setPlayBtnStatus] = useState(false);
  const videoRef = useRef()
  const playHandle = () => {
    
  }
  
  // 如果视频是播放状态，隐藏播放按钮
useEffect(()=>{
  props.videoRef.current.addEventListener('playing',()=>(setPlayBtnStatus(true)))
},[])
const config = {
  playBtnStatus:playBtnStatus,
  videoRef:props.videoRef,
  playHandle:playHandle
}
  
  return (
    <VideoComponent {...props} {...config}/>
  );
};

export default VideoPlay;