/**
 * 【数据指数-模块】
 */
import React, { useContext } from 'react';
import DataExponent from '../../../../components/data_exponent';
import { IndexContext } from '../../index';
import styles from './index.module.scss';
const Index = (props) => {
  const { exponentData } = useContext(IndexContext);
  const data = exponentData ?Object.values(exponentData):[]
  return (
    <div className={styles.exponentData}>
      {data.map((item, index) => (
        <div className={styles.inExponentData} key={index}>
          <DataExponent data={item}  />
        </div>
      ))}
    </div>
  );
};

export default Index;
