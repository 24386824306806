/**
 * 【最新水情-模块】
 */
import React from 'react';
import styles from './index.module.scss';
import Container from '@/components/container';
import Table from '@/components/table_component';
import icon from '@/assets/images/icon/icon_chart.png';
import { dpr } from '@/utils';
const columns = [
  {
    title: '车牌',
    dataIndex: 'CarLicense',
    key: 'CarLicense',
    width: 110,
  },
  {
    title: '司机名称',
    dataIndex: 'name',
    key: 'name',
    width: 100,
  },
  {
    title: '手机号',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: '进场时间',
    dataIndex: 'approachtime',
    key: 'approachtime',
    width: 90,
  },
  {
    title: '离场时间',
    dataIndex: 'departureTime',
    key: 'departureTime',
    width: 90,
    render: (record) => (
      <span>{record.status === '在场' ? '——' : record.departureTime}</span>
    ),
  },
  {
    title: '在/离场状态',
    dataIndex: 'status',
    key: 'status',
    width: 90,
  },
];
const Index = (props) => {
  return (
    <div className={styles.tableWrapper}>
      <Table
        columns={columns}
        dataSource={props.carManagement || []}
        scorll={{ y: 192 * dpr }}
        {...props}
        style={{ width: 680 * dpr }}
      />
    </div>
  );
};

export default Index;
