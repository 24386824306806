/**
 * 【最新动态】
 */
import React,{ useContext} from 'react';
import Container from '@/components/container';
import styles from './index.module.scss';
import NewsList from '@/components/news_list/news_scroll'
import { IndexContext } from '../../index';
// import { newsDatalist } from '../../../../mock/news'
const Index = () => {
  const { newsList } = useContext(IndexContext)
  return (
    <div className={styles.newsWrapper}>
      <Container
        text="最新动态"
        className={styles.container}
        hasTitle={true}
      >
        <NewsList newsDatalist={newsList}/>
      </Container>
    </div>
  );
};

export default Index;
