/**
 * 【table封装】
 * 用法同 antd-table
 * @param {props} object
 * @param {columns} array
 * @param {dataSource} array
 * @param {scorll}  指定滚动区域高度 
 * scorll={{y:800}}
 * const columns =[
  {
    title: '名字',
    dataIndex: 'name',
    key: 'name',
    width: '0.417rem', // table 的宽度需要换算为rem，不然自适应会有问题
  }]
  const dataSource= [{
     key: i,
    name: `Edward King `,
  }]
 */
import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { dpr } from '@/utils';
const TableComponent = (props) => {
  const [columns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    setColumns(props.columns || []);
    setDataSource(props.dataSource || []);
  }, [props]);

  const TdItem = ({ data }) => {
    return (
      <tr>
        {columns.map((item, index) => (
          <td
            style={{
              width:
                typeof item.width === 'string' ? item.width : `${item.width * dpr}px`,
            }}
            key={data.key ? Math.random() + data.key : Math.random() + index}
          >
            {item.render ? item.render(data) : data[item.dataIndex]}
          </td>
        ))}
      </tr>
    );
  };

  return (
    <div className={styles.tableComponent} style={{...props.style}}>
      <div className={styles.tableHead}>
        <table>
          <thead>
            <tr>
              {columns.map((item, index) => (
                <th
                  style={{
                    width:
                      typeof item.width === 'string'
                        ? item.width
                        : `${item.width * dpr}px`,
                  }}
                  key={
                    item.key ? Math.random() + item.key : Math.random() + index
                  }
                >
                  {item.title}
                </th>
              ))}
            </tr>
          </thead>
        </table>
      </div>
      <div
        className={styles.tbodyWrapper}
        style={{
          height: props.scorll ? props.scorll.y : 'auto',
          scrollBehavior: props.scrollNum === 0 ? '' : 'smooth',
        }}
        ref={props.tableRef}
      >
        <table scrolltop={10} ref={props.tableListRef}>
          <tbody>
            {dataSource.length === 0 ? (
              <tr>
                <td>
                  <div className={styles.dataEmpty}>无数据</div>
                </td>
              </tr>
            ) : (
              dataSource.map((item, index) => (
                <TdItem
                  key={
                    item.key ? Math.random() + item.key : Math.random() + index
                  }
                  data={item}
                />
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableComponent;
