/**
 * 【新闻自动无缝滚动】
 */
import React, { useEffect, useState, useRef } from 'react';
import styles from './index.module.scss';
import NewsItem from './news_item';
import useInterval from '@/hooks/useInterval';
import {dpr} from '@/utils'

const NewsScroll = (props) => {
  const [topDistance, setTopDistance] = useState(0);
  const [state, setState] = useState(true);
  const wrapperRef = useRef();
  const listRef = useRef();

  const { newsDatalist } = props

  useInterval(() => {
    setState(true);
    setTopDistance(topDistance - (54*dpr));
    if (
      listRef.current.clientHeight + topDistance - (54*dpr) <
        wrapperRef.current.clientHeight ||
      listRef.current.clientHeight + topDistance ===
        wrapperRef.current.clientHeight
    ) {
      setState(false);
      setTopDistance(0);
    }
  }, 5000);
  return (
    <div className={styles.newsWrapper} ref={wrapperRef}>
      <div
        className={styles.newsList}
        style={{
          top: `${topDistance}px`,
          // top: `${÷topDistance / 192}rem`,
          transition: state ? 'top 0.5s' : '',
        }}
        ref={listRef}
      >
        {newsDatalist.map((item, index) => (
          <NewsItem  key={index} item={item} />
        ))}
      </div>
    </div>
  );
};

export default NewsScroll;
