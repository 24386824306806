/**
 * 【头部右边部分】
 */
import React from 'react';
import styles from './index.module.scss';
import iconUrl from '../../assets/images/icon/icon_screen.png';
const TitleRight = (props) => {
  return (
    <div
      className={styles.titleRight}
      onClick={() => props.goToNextPage(props.rightText)}
    >
      <div className={styles.icon}>
        <img src={iconUrl} alt="" />
      </div>
      <p className={styles.text}>{props.rightText}</p>
    </div>
  );
};

export default TitleRight;
