/**
 * 【折线图控件】
 * @param { xAxisData } Array x轴坐标刻度标签内容
 * @param { seriesData } Array 用于展示的图表数据
 * @param { id } String  图标id
 * @param { className } String  可供自定义样式
 * @param { isMore } Bool  是否是多数据
 */
import React, { useState, useEffect } from 'react';
import styles from './style/line_graph.module.scss';
import * as echarts from 'echarts/lib/echarts';
import { fontSize } from '@/utils/utils';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/grid';
import 'echarts/lib/component/markPoint';
export default function LineGraph(props) {
  console.error(props.min)
  const [lineOption] = useState({
    grid: {
      top: 90,
    },
    legend: {
      top: fontSize(31),
      right: 0,
      data: [
        // {
        //   name: "组骨料",
        //   icon: "circle",
        // },
        // {
        //   name: "机制砂",
        //   icon: "circle",
        // },
        // {
        //   name: "天然砂",
        //   icon: "circle",
        // },
      ],
      textStyle: {
        color: 'rgba(255,255,255,.65)',
        fontSize: fontSize(15),
        fontFamily: 'CN-Regular',
        fontWeight: 400,
      },
    },
    xAxis: {
      min: 'dataMin',
      data: ['1日', '5日', '10日', '15日', '20日', '25日', '30日'],
      axisTick: false,
      axisLabel: {
        color: 'rgba(255,255,255,.65)',
        fontSize: fontSize(15),
        fontFamily: 'CN-Regular',
        fontWeight: 400,
      },
    },
    yAxis: {
      name: '吨',
      min: 0,
      nameTextStyle: {
        color: 'rgba(255,255,255,.65)',
        fontSize: fontSize(13),
        fontFamily: 'CN-Regular',
        fontWeight: 400,
        padding: [0, 0, 0, -fontSize(0)],
      },
      axisLine: false,
      axisTick: false,
      axisLabel: {
        color: 'rgba(255,255,255,.65)',
        fontSize: fontSize(15),
        fontFamily: 'CN-Regular',
        fontWeight: 400,
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(255,255,255,0.15)',
        },
      },
    },
    series: [
      {
        type: 'line',
        data: [5, 20, 36, 10, 10, 20, 4],
        lineStyle: {
          color: 'rgba(243,204,98,1)',
        },
        areaStyle: {
          color: 'rgba(246,189,22,.25)',
        },
        itemStyle: {
          opacity: 0,
        },
        smooth: 0.3,
      },
    ],
  });
  const [moreLineOption] = useState({
    grid: {
      top: fontSize(80),
    },
    legend: {
      top: fontSize(31),
      right: 0,
      data: [
        // {
        //   name: "组骨料",
        //   icon: "circle",
        // },
        // {
        //   name: "机制砂",
        //   icon: "circle",
        // },
        // {
        //   name: "天然砂",
        //   icon: "circle",
        // },
      ],
      textStyle: {
        color: 'rgba(255,255,255,.65)',
        fontSize: fontSize(15),
        fontFamily: 'CN-Regular',
        fontWeight: 400,
      },
    },
    xAxis: {
      min: 'dataMin',
      data: ['一月', '二月', '三月', '四月', '五月'],
      axisTick: false,
      axisLabel: {
        color: 'rgba(255,255,255,.65)',
        fontSize: fontSize(15),
        fontFamily: 'CN-Regular',
        fontWeight: 400,
      },
    },
    yAxis: {
      name: '',
      // min: 'dataMin',
      min: 0,
      nameTextStyle: {
        color: 'rgba(255,255,255,.65)',
        fontSize: fontSize(13),
        fontFamily: 'CN-Regular',
        fontWeight: 400,
        padding: [0, 0, fontSize(20), fontSize(0)],
      },
      axisLine: false,
      axisTick: false,
      axisLabel: {
        color: 'rgba(255,255,255,.65)',
        fontSize: fontSize(12),
        fontFamily: 'CN-Regular',
        fontWeight: 400,
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(255,255,255,0.15)',
        },
      },
    },
    series: [
      {
        name: '组骨料',
        type: 'line',
        data: [5, 20, 36, 10, 10, 20, 4],
        itemStyle: {
          color: 'rgba(170,98,21,1)',
        },
        smooth: 0.2,
      },
      // {
      //   name: '机制砂',
      //   type: 'line',
      //   data: [15, 25, 30, 8, 13, 21, 20],
      //   itemStyle: {
      //     color: 'rgba(54,207,201,1)',
      //   },
      //   symbol: 'diamond',
      //   symbolSize: fontSize(8),
      //   smooth: 0.2,
      // },
      // {
      //   name: '天然砂',
      //   type: 'line',
      //   data: [15, 40, 36, 10, 10, 20, 14],
      //   itemStyle: {
      //     color: 'rgba(243,204,98,1)',
      //   },
      //   smooth: 0.2,
      // },
    ],
  });
  useEffect(() => {
    props.isMore ? initOption(moreLineOption) : initOption(lineOption);
  }, []);
  const initOption = (data) => {
    let myChart = echarts.init(document.getElementById(props.id));
    if (props.xAxisData) {
      data.xAxis.data = props.xAxisData;
      // props.xAxisData.forEach((item, index) => {

      // });
    }
    if (props.hasYAxis) {
      if (typeof props.yAxisName === 'string') {
        data.yAxis.name = props.yAxisName;
      } else if (typeof props.yAxisName === 'object') {
        props.yAxisName.forEach((item, index) => {
          data.yAxis[index].name = item;
        });
      }
    } else {
      data.yAxis.name = '';
    }

    if (props.seriesData) {
      /**
       * 为了共用，data.series数据不可写死
       * 需要根据数据自动生成，颜色需要支持传入
       */
      data.series = props.seriesData.map((item, index) => ({
        ...data.series[0],
        data: item,
        name: props.legendData ? props.legendData[index] : [],
        itemStyle: {
          color: props.seriesColor
            ? props.seriesColor[index]
            : 'rgba(170,98,21,1)',
        },
      }));
      // props.seriesData.forEach((item, index) => {
      //   data.series[index].data = item;
      // });
    }
    // if (props.legendData) {
    //   data.legend.data = props.legendData
    // }
    if (props.legendData && props.isMore) {
      data.legend.data = props.legendData.map((item, index) => {
        return {
          icon: 'circle',
          name: item,
        };
      });
    } else if(props.legendData) {
      data.legend.data = props.legendData.map((item, index) => {
        return {
          icon: 'circle',
          name: item,
        };
      });
    }

    myChart.clear();
    myChart.setOption(data);
    myChart.resize();
  };
  return (
    <div
      className={`${styles.lineGraph} ${
        props.className ? props.className : ''
      }`}
    >
      <div id={props.id} style={{ width: '100%', height: '100%' }}></div>
    </div>
  );
}
