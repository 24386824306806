/**
 * 【环境监测】
 */
import React, { useState } from 'react';
import Container from '@/components/container';
import Tabs from '@/components/tab';
import styles from './style/environmental.module.scss';
import { Carousel } from 'antd-mobile';
// import {} from '@/mock/environment_data.js'
import WaterQuality from './waterQuality';
import Noise from './noise';
import AirQuality from './airQuality';
// import Qweather from './qweather';
const Environmental = (props) => {
  const [active, setActive] = useState(0);

  const beforeChange = (e) => {
    setActive(e);
  };
  return (
    <Container
      text="环境监测"
      showIsMore={false}
      className={styles.bgc}
      hasTitle={true}
    >
      <Tabs
        // list={['排水水质', '噪声', '空气质量', '温湿度']}
        list={['排水水质', '噪声', '空气质量']}
        // onChange={onChange}
        active={active}
      />
      <Carousel
        autoplay={true}
        autoplayInterval={5000}
        infinite
        dots={false}
        afterChange={beforeChange}
      >
        <WaterQuality />
        <Noise />
        <AirQuality />
        {/* 温湿度模块 */}
        {/* <Qweather /> */}
      </Carousel>
    </Container>
  );
};
export default Environmental;
