/**
 * 【新闻单行】
 */
import React from 'react';
import styles from './index.module.scss';

const NewsItem = (props) => {
  const { item } = props
  return (
    <div className={styles.newsItem}>
      <div className={styles.date}>{item.tradeTime}</div>
      <div className={styles.lineWrapper}>
        <span className={styles.dot}></span>
        <span className={styles.line}></span>
      </div>
      <div className={styles.company}>{item.buyerName}下单{item.goodsName}{item.goodsWeight}吨</div>
    </div>
  );
};

export default NewsItem;
