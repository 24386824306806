/**
 * 【显示视频的title】
 * @param {props} object
 * @param {title} string  视频名称
 */
import React from 'react';
import styles from './index.module.scss';
const VideoTitle = (props) => {
  return <div className={styles.videoTitle}>{props.title}</div>;
};

export default VideoTitle;
