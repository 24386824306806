/**
 * 【噪声】
 */
import React from 'react';
import BarGraph from '@/components/bar_graph';
import styles from './style/environmental.module.scss';
import data from '@/mock/environment_data';

const Noise = () => {
  const { noise } = data
  return (
    <BarGraph
      className={styles.LineGraph}
      id="line-graph-Noise"
      xAxisData={[...noise.x]}
      legendData={[]}
      type="single"
      yAxisName='单位：db'
      hasYAxis={true}
      seriesData={[[...noise.y]]}
    />
  );
};

export default Noise;
