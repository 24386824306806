/**
 * 能耗-数据
 */
import Mock from 'mockjs';
/**
 * 燃油
 * @param {uelOil} 燃油量
 * @param {discharge} 排放量
 * 数据生成规则：
 * 燃油量（吨）：200-300之间
 * 排放量（吨CO2）燃油量*3.1863
 */
const fuelOilList = new Array(7).fill('').map((item, index) =>
  Mock.mock({
    'uelOil|200-300': 200,
    day: index ? `${index * 5}日` : `${index + 1}日`,
  })
);
const fuelOilListX = fuelOilList.map((item) => item.day);
const fuelOilListY = fuelOilList.map((item) => item.uelOil);
/**
 * 排放量
 */
const dischargeY = fuelOilListY.map((item) => item * 3.1863);

/**
 * 耗电量
 * @param {powerConsumption} 耗电量
 * 数据生成规则：
 * 耗电量（kWh）：20000~30000之间的随机值
 */
const powerConsumption = new Array(7).fill('').map((item, index) =>
  Mock.mock({
    'powerConsumption|20000-30000': 20000,
    day: index ? `${index * 5}日` : `${index + 1}日`,
  })
);

const powerConsumptionX = powerConsumption.map((item) => item.day);
const powerConsumptionY = powerConsumption.map((item) => item.uelOil);
export default {
  fuelOil: {
    x: fuelOilListX,
    y: fuelOilListY,
  },
  discharge: {
    y: dischargeY,
    x: fuelOilListX,
  },
  powerConsumption: {
    x: powerConsumptionX,
    y: powerConsumptionY,
  },
};
