/**
 * 【logo】
 */
import React from 'react';
import styles from './index.module.scss'
import Logo from '@/assets/images/logo.svg'
const TitleLogo = () => {
  return (
    <div className={styles.logo}>
      <img src={Logo} alt=""/>
    </div>
  );
};

export default TitleLogo;