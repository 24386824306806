/**
 * 【地图弹框-逻辑处理】
 */
import React, { useState, useRef, useEffect, useContext } from 'react';
import MapPopUp from '@/components/map_pop_up';
import { mapMarkList } from '@/mock/map_data';
import styles from './index.module.scss';
import Video from '@/components/video_component/video_play';
import useInterval from '@/hooks/useInterval';
import { IndexContext } from '../../index';
const contentList = [
  { key: '船名', value: '桂8888' },
  { key: '货物', value: '碎石' },
  { key: '数量', value: '89999吨' },
  { key: '到货时间', value: '11月26日' },
];

const Index = (props) => {
  const { getCurrent, wayBill } = useContext(IndexContext);
  const mapVideoRef = useRef();
  const videoCinfig = {
    autoPlay: 'autoplay',
    loop: 'loop',
  };
  const [currenUrl, setCurrentUrl] = useState('');
  const [duration, setDuration] = useState(1);
  const [num, setNum] = useState(0);
  const [dataList, steDataList] = useState(contentList);
  const [imageUrls, setImageUrls] = useState([]);
  const [mapMarkListData, setMapMarkListData] = useState([mapMarkList[0]]);
  let list = JSON.parse(JSON.stringify(mapMarkListData));

  useEffect(() => {
    if (wayBill.length > 0) {
      setMapMarkListData([...wayBill]);
      console.log('-----', wayBill);
      setCurrentUrl(wayBill[0].videoUrl);
      setItemData(wayBill[0]);
      setImageUrls(wayBill[0].imageUrls);
      wayBill[0].isSelect = true;
      getCurrent(wayBill, 0);
    }

    mapVideoRef.current.addEventListener('playing', () => {
      setDuration(mapVideoRef.current.duration);
    });
  }, [wayBill]);

  useEffect(() => {
    setCurrentUrl(mapMarkListData[num]?.videoUrl);
    setItemData(mapMarkListData[num]);
    setImageUrls(mapMarkListData[num].imageUrls);
    // mapMarkListData[num].isSelect = true

    let newList = list.map((item, index) => {
      if (index === num) {
        return {
          ...item,
          isSelect: true,
        };
      } else {
        return {
          ...item,
          isSelect: false,
        };
      }
    });
    getCurrent(newList, num);
  }, [num]);

  useInterval(() => {
    if (num === mapMarkListData.length - 1) {
      setNum(0);
      return false;
    } else {
      setNum(num + 1);
    }
  }, 20000);
  // }, duration * 1000);

  const setItemData = (item) => {
    let list = [
      { key: '船名', value: item.name },
      { key: '货物', value: item.goods },
      { key: '数量', value: `${item.weight} 吨 ` },
      { key: '到货时间', value: item.date },
    ];
    steDataList(list);
  };
  return (
    <div className={styles.mapPopUp}>
      <MapPopUp contentList={dataList} imageUrls={imageUrls}>
        <div style={{ height: `${125 / 192}rem`, padding: `${10 / 192}rem`, overflow: 'hidden' }}>
          <Video
            videoUrl={currenUrl}
            videoRef={mapVideoRef}
            videoCinfig={videoCinfig}
          />
        </div>
      </MapPopUp>
    </div>
  );
};

export default Index;
