/**
 * 【item-数字+文字描述】
 * @param {props} object
 * @param { item } object
 */
import React from 'react';
import styles from './index.module.scss'
const DataItem = ({item}) => {
  return (
    <div>
      <div className={styles.data}>{item.name}</div>
      <span className={styles.text}>{item.text}</span>
    </div>
  );
};

export default DataItem;
