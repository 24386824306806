/**
 * 【地图弹框-逻辑处理】
 */
import React, { useState, useRef, useEffect, useContext } from 'react';
import MapPopUp from '@/components/map_pop_up';
import { mineMapData } from '@/mock/map_data';
import styles from './index.module.scss';
import useInterval from '@/hooks/useInterval';
import { SmartMineContext } from '../../index';
const contentList = [
  { key: '船名', value: '桂8888' },
  { key: '货物', value: '碎石' },
  { key: '数量', value: '89999吨' },
  { key: '到货时间', value: '11月26日' },
];

let list = JSON.parse(JSON.stringify(mineMapData.slice(0,2)));

const Index = (props) => {
  const { getCurrent } = useContext(SmartMineContext);
 
  const [num, setNum] = useState(0);
  const [dataList, steDataList] = useState(contentList);
  const [imageUrls, setImageUrls] = useState([]);
  useEffect(() => {
    // mapVideoRef.current.addEventListener('playing', () => {
    //   setDuration(mapVideoRef.current.duration);
    // });
    setItemData(list[0]);
    setImageUrls(list[0].imageUrls);
    list[0].isSelect = true;
    // getCurrent(mineMapData, 0);
  }, []);

  useEffect(() => {
    setItemData(list[num]);
    setImageUrls(list[num].imageUrls);

    let newList = list.map((item, index) => {
      if (index === num ) {
        return {
          ...item,
          isSelect: true,
        };
      } else {
        return {
          ...item,
          isSelect: false,
        };
      }
    });
    getCurrent(newList, num);
  }, [num]);

  useInterval(() => {
    if (num === list.length - 1) {
      setNum(0);
      return false;
    } else {
      setNum(num + 1);
    }
    }, 20000);

  const setItemData = (item) => {
    let listObj = [
      { key: '矿山名', value: item.name },
      { key: '实时人员数量', value: item.personnelTotal },
      { key: '实时车辆数量', value: `${item.carTotal} ` },
    ];
    steDataList(listObj);
  };
  return (
    <div className={styles.mapPopUp}>
      <MapPopUp contentList={dataList} imageUrls={imageUrls}>
      </MapPopUp>
    </div>
  );
};

export default Index;
