/**
 * 【矿山视频】
 */

import React, { useRef, useState, useEffect } from 'react'
import Container from '@/components/container'
import styles from './index.module.scss'
import VideoComponent from '@/components/video_component'
import VideoPlayComponent from '@/components/video_component/video_play'
import { Carousel } from 'antd-mobile'
import { dpr } from '@/utils'
import useInterval from '@/hooks/useInterval'

const currenClassName = '3px solid #e2b080'
const defaultClassName = '1px solid #E2B080'

const Index = (props) => {
  const listRef = useRef()
  const wrapperRef = useRef()
  const [top, setTop] = useState(0)
  const videoCinfig = {
    autoPlay: 'autoplay',
    // loop: 'loop'
  }

  useEffect(()=>{
    console.log('++++',props.videoUrl);
  },[props.videoUrl])

  // 列表滚动定时器
  useInterval(() => {
    if (
      wrapperRef.current.clientHeight / dpr ===
        listRef.current.clientHeight / dpr + top ||
      wrapperRef.current.clientHeight / dpr >
        listRef.current.clientHeight / dpr + top
    ) {
      setTop(0)
        return
    }
    setTop(top - 1)
  }, 30)
  return (
    <div className={styles.videoWrapper}>
      <Container text="矿山实时采矿视频" className={styles.bgc} hasTitle={true}>
        <div className={styles.inVideoWrapper}>
          <div className={styles.videoCarousel}>
            {/*<Carousel
              autoplay={true}
              autoplayInterval={props.duration * 1000}
              infinite
              dots={false}
            >*/}
              {/* {[props.videoUrl].map((item, index) => ( */}
                <VideoPlayComponent
                  // key={index}
                  {...props}
                  style={{ width: `${414 / 192}rem`, padding: `${5 / 192}rem` }}
                  videoCinfig={videoCinfig}
                  title="矿山视频"
                  videoUrl={props.videoUrl}
                />

            {/*</Carousel>*/}
          </div>

          <div className={styles.videos_box}>
            <div className={styles.videoPreview} ref={wrapperRef}>
              <div
                className={styles.videoWrapper_in}
                ref={listRef}
                style={{ top: top + 'px' }}
              >
                {props?.urlList?.map((item, index) => (
                  <VideoComponent
                    style={{
                      width: `${121 / 192}rem`,
                      height: `${68 / 192}rem`,
                      marginBottom: `${10 / 192}rem`,
                      border:
                        props.num === index ? currenClassName : defaultClassName
                    }}
                    playIconSize={{ width: `${14 / 192}rem` }}
                    videoUrl={item}
                    key={index}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Index
