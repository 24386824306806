/**
 * 【耗电量】
 */

import React from 'react';
import styles from './style/index.module.scss';
import data from '@/mock/energy_data';
import LineGraph from '@/components/line_graph';

const PowerConsumption = () => {
  const { discharge,powerConsumption } = data;
  return (
    <LineGraph
      className={styles.LineGraph}
      id="line1-graph-PowerConsumption"
      seriesData={[discharge.y]}
      xAxisData={[...discharge.x]}
      seriesColor={['rgba(170,98,21,1)', 'rgba(54,207,201,1)']}
      hasYAxis={true}
      yAxisName="单位：kWh"
    />
  );
};

export default PowerConsumption;
