/**
 * 【噪声】
 */
import React from 'react';
import BarGraph from '@/components/bar_graph';
import styles from './style/index.module.scss';
import data from '@/mock/energy_data';
import LineGraph from '@/components/line_graph';
const FuelOil = () => {
  const { fuelOil } = data
  return (
    // <BarGraph
    //   className={styles.LineGraph}
    //   id="line-graph-fuelOil"
    //   legendData={[]}
    //   type="single"
    //   yAxisName='单位：吨'
    //   hasYAxis={true}
    //   xAxisData={[...fuelOil.x]}
    //   seriesData={[[...fuelOil.y]]}
    // />
    <LineGraph
      className={styles.LineGraph}
      id="line-graph-fuelOil"
      xAxisData={[...fuelOil.x]}
      seriesData={[[...fuelOil.y]]}
      // seriesColor={['rgba(170,98,21,1)', 'rgba(54,207,201,1)']}
      hasYAxis={true}
      yAxisName='单位：吨'
    />
  );
};

export default FuelOil;
