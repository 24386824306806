/**
 * 【折线图控件】
 * @param { xAxisData } Array x轴坐标刻度标签内容
 * @param { seriesData } Array 用于展示的图表数据
 * @param { id } String  图标id
 * @param { className } String  可供自定义样式
 * @param { isMore } Bool  是否是多数据
 */
import React, { useState, useEffect } from 'react';
import styles from './style/line_graph.module.scss';
import * as echarts from 'echarts/lib/echarts';
import { fontSize } from '@/utils/utils';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/grid';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/markPoint';
import 'echarts/lib/component/title';
export default function LineGraph(props) {
  const [lineOption] = useState({
    title: {
      show:true,
      left: 'center',
      text: '',
      padding:[10,0,0,0],
      subtext: '',
      textStyle:{
        color: 'rgba(255,145,35,1)',
        fontSize: 16,
        fontFamily: 'CN-Regular',
        fontWeight: 400,
      }
    },
    grid: {
      top: 15,
      right: 25
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(50,50,50,0.5)',
      borderColor: '#3a3a3b',
      textStyle: {
        fontWeight: 'normal',
        fontSize: 14,
        color: '#969798'
      },
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    legend: {
      bottom: 0,
      x: 'center',
      itemHeight: 10,
      itemGap: 5,
      data: [
        // {
        //   name: "组骨料",
        //   icon: "circle",
        // },
        // {
        //   name: "机制砂",
        //   icon: "circle",
        // },
        // {
        //   name: "天然砂",
        //   icon: "circle",
        // },
      ],
      textStyle: {
        color: 'rgba(255,255,255,.65)',
        fontSize: 13,
        fontFamily: 'CN-Regular',
        fontWeight: 400,
      },
    },
    xAxis: {
      min: 'dataMin',
      data: ['1日', '5日', '10日', '15日', '20日', '25日', '30日'],
      axisTick: false,
      axisLabel: {
        color: 'rgba(255,255,255,.65)',
        fontSize: fontSize(15),
        fontFamily: 'CN-Regular',
        fontWeight: 400,
        interval: 3,
        showMaxLabel: true
      },
    },
    yAxis: {
      name: '吨',
      min: (value)=>{
        return value.min-5<=0?0:value.min-5
      },
      max: (value)=>{
        return value.max+5
      },
      nameTextStyle: {
        color: 'rgba(226,176,128,1)',
        fontSize: fontSize(14),
        fontFamily: 'CN-Regular',
        fontWeight: 500,
        padding: [0, 0, -3, -25],
        align: 'right'
      },
      axisLine: false,
      axisTick: false,
      axisLabel: {
        color: 'rgba(255,255,255,.65)',
        fontSize: fontSize(15),
        fontFamily: 'CN-Regular',
        fontWeight: 400,
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(255,255,255,0.15)',
        },
      },
    },
    series: [
      {
        type: 'line',
        data: [5, 20, 36, 10, 10, 20, 4],
        lineStyle: {
          color: 'rgba(243,204,98,1)',
        },
        areaStyle: {
          color: 'rgba(246,189,22,.25)',
        },
        itemStyle: {
          opacity: 0,
        },
        smooth: 0.3,
      },
    ],
  });
  const [moreLineOption] = useState({
    title: {
      show:true,
      left: 'center',
      text: '',
      subtext: '',
      padding:[10,0,0,0],
      textStyle:{
        color: 'rgba(255,145,35,1)',
        fontSize: 16,
        fontFamily: 'CN-Regular',
        fontWeight: 400,
      }
    },
    grid: {
      top: 15,
      right: 25
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(50,50,50,0.5)',
      borderColor: '#3a3a3b',
      textStyle: {
        fontWeight: 'normal',
        fontSize: 14,
        color: '#969798'
      },
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    legend: {
      bottom: 0,
      x: 'center',
      itemHeight: 10,
      itemGap: 5,
      data: [
        // {
        //   name: "组骨料",
        //   icon: "circle",
        // },
        // {
        //   name: "机制砂",
        //   icon: "circle",
        // },
        // {
        //   name: "天然砂",
        //   icon: "circle",
        // },
      ],
      textStyle: {
        color: 'rgba(255,255,255,.65)',
        fontSize: 13,
        fontFamily: 'CN-Regular',
        fontWeight: 400,
      },
    },
    xAxis: {
      min: 'dataMin',
      data: ['一月', '二月', '三月', '四月', '五月'],
      axisTick: false,
      axisLabel: {
        color: 'rgba(255,255,255,.65)',
        fontSize: fontSize(15),
        fontFamily: 'CN-Regular',
        fontWeight: 400,
        interval: 3,
        showMaxLabel: true
      },
    },
    yAxis: {
      name: '',
      min: (value)=>{
        return value.min-5<=0?0:value.min-5
      },
      max: (value)=>{
        return value.max+5
      },
      nameTextStyle: {
        color: 'rgba(226,176,128,1)',
        fontSize: fontSize(14),
        fontFamily: 'CN-Regular',
        fontWeight: 500,
        padding: [0, 0, -3, -25],
        align: 'right'
      },
      axisLine: false,
      axisTick: false,
      axisLabel: {
        color: 'rgba(255,255,255,.65)',
        fontSize: fontSize(12),
        fontFamily: 'CN-Regular',
        fontWeight: 400,
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(255,255,255,0.15)',
        },
      },
    },
    series: [
      {
        name: '组骨料',
        type: 'line',
        data: [5, 20, 36, 10, 10, 20, 4],
        itemStyle: {
          color: 'rgba(170,98,21,1)',
        },
        smooth: 0.2,
      },
      // {
      //   name: '机制砂',
      //   type: 'line',
      //   data: [15, 25, 30, 8, 13, 21, 20],
      //   itemStyle: {
      //     color: 'rgba(54,207,201,1)',
      //   },
      //   symbol: 'diamond',
      //   symbolSize: fontSize(8),
      //   smooth: 0.2,
      // },
      // {
      //   name: '天然砂',
      //   type: 'line',
      //   data: [15, 40, 36, 10, 10, 20, 14],
      //   itemStyle: {
      //     color: 'rgba(243,204,98,1)',
      //   },
      //   smooth: 0.2,
      // },
    ],
  });
  useEffect(() => {
    props.isMore ? initOption(moreLineOption) : initOption(lineOption);
  }, [props]);
  const initOption = (data) => {
    let myChart = echarts.init(document.getElementById(props.id));
    if (props.xAxisData) {
      data.xAxis.data = props.xAxisData;
      // props.xAxisData.forEach((item, index) => {

      // });
    }
    if (props.hasYAxis) {
      if (typeof props.yAxisName === 'string') {
        data.yAxis.name = props.yAxisName;
      } else if (typeof props.yAxisName === 'object') {
        props.yAxisName.forEach((item, index) => {
          data.yAxis[index].name = item;
        });
      }
    } else {
      data.yAxis.name = '';
    }
    // data.title.text = props.yAxisName

    if (props.seriesData) {
      /**
       * 为了共用，data.series数据不可写死
       * 需要根据数据自动生成，颜色需要支持传入
       */
      data.series = props.seriesData.map((item, index) => ({
        ...data.series[0],
        data: item,
        name: props.legendData ? props.legendData[index] : [],
        itemStyle: {
          color: props.seriesColor
              ? props.seriesColor[index]
              : 'rgba(170,98,21,1)',
        },
      }));
      // props.seriesData.forEach((item, index) => {
      //   data.series[index].data = item;
      // });
    }
    // if (props.legendData) {
    //   data.legend.data = props.legendData
    // }
    if (props.legendData && props.isMore) {
      data.legend.data = props.legendData.map((item, index) => {
        return {
          icon: 'circle',
          name: item,
        };
      });
    } else if(props.legendData) {
      data.legend.data = props.legendData.map((item, index) => {
        return {
          icon: 'circle',
          name: item,
        };
      });
    }
    // console.log(data)

    myChart.clear();
    myChart.setOption(data);
    myChart.resize();
  };
  return (
      <div
          className={`${styles.lineGraph} ${
              props.className ? props.className : ''
          }`}
      >
        <div id={props.id} style={{ width: '100%', height: '100%' }}></div>
      </div>
  );
}
