/**
 * 【施工管理】
 */
import React, { useState } from 'react';
import Container from '@/components/container';
import styles from './index.module.scss';
import Tabs from '@/components/tab';
import Personnel from './personnelScroll';
import CarRecord from './carRecord/table_scroll';
import data from '@/mock/constructionSupervision';

const { personnelData, carManagement } = data;

const Index = () => {
  const [active, setActive] = useState(0);
  const onChange = (e) => {
    setActive(e);
  };
  return (
    <Container
      text="施工管理"
      showIsMore={false}
      className={styles.bgc}
      hasTitle={true}
    >
      <Tabs list={['人员记录', '车辆记录']} active={active} />
      <div className={styles.roadworkMain}>
        {active === 0 ? (
          <Personnel
            onChange={onChange}
            active={active}
            personnelData={personnelData}
          />
        ) : (
          <CarRecord
            onChange={onChange}
            active={active}
            carManagement={carManagement}
          />
        )}
      </div>
    </Container>
  );
};

export default Index;
