/**
 * 【横向滚动-并自动跳转到当前tab】
 */
import { Tabs } from 'antd-mobile';
import React, { useEffect, useState } from 'react';
import styles from './style/scroll_tab.module.scss';

const ScrollTab = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    setActiveTab(props.activeTab);
    // onChange()
  }, [props.activeTab]);

  // const onChange = () => {
  // }
  return (
    <div className={styles.scrollTab}>
      <Tabs
        tabs={props.tabs}
        // onTabClick={onChange}
        renderTabBar={(props) => (
          <Tabs.DefaultTabBar {...props} page={5} activeTab={activeTab} />
        )}
      >
        {props.children}
      </Tabs>
    </div>
  );
};

export default ScrollTab;
