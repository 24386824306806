import { dataTool } from 'echarts/lib/echarts';
import React from 'react';
import styles from './style/staff_records.module.scss';
export default function stallRecords(props) {
  const { data } = props;
  return (
    <div className={styles.wrapper}>
      <div className={styles.staffRecords}>
        <div className={styles.info}>
          <div className={styles.headPortrait}>
            <img src={data.headPortrait} alt="" />
          </div>
          <div className={styles.infoDetails}>
            <div>
              <span className={styles.name}>{data.name}</span>
              <span className={styles.job}>{data.job}</span>
            </div>
            <div className={styles.phone}>
              <span>手机号：</span>
              <span>{data.phone}</span>
            </div>
          </div>
        </div>
        <div className={styles.time}>
          <span>进场时间 / 离场时间：</span>
          <span>
            {data.approachtime} /{' '}
            {data.status === '在场' ? '——' : data.departureTime}
          </span>
        </div>
        <div className={styles.status}>
          <span>在/离场状态：</span>
          <span
            style={{ color: data.status === '在场' ? '#52C41A' : '#F5222D' }}
          >
            {data.status}
          </span>
        </div>
      </div>
      <div className={styles.dashed}></div>
    </div>
  );
}
