/**
 * 【地图弹框】
 * @param {props} object
 * @param {contentList} array 内容list
 * @param {imageUrls}array 图片url集合
 * const contentList = [
  { key: '船名', value: '桂8888' },
  { key: '货物', value: '碎石' },
  { key: '数量', value: '89999吨' },
  { key: '到货时间', value: '11月26日' },
];
const imageUrls = [url,url,url,url];
 */
import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';

const MapPopUp = (props) => {
  const [contentList, setContentList] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  useEffect(() => {
    setContentList(props.contentList || []);
    setImageUrls(props.imageUrls || []);
  }, [props]);
  // 弹框内容部分
  const Content = ({ item }) => {
    return (
      <div className={styles.content}>
        <span className={styles.key}>{item.key}:</span>
        <span className={styles.value}>{item.value}</span>
      </div>
    );
  };

  // 弹窗图片部分
  const Images = ({ item }) => {
    return (
      <div className={styles.imgWrapper}>
        {imageUrls.map((item, index) => (
          <div key={index}>
            <img src={item} alt="" />
          </div>
        ))}
      </div>
    );
  };
  return (
    <div className={styles.mapPopUp}>
      {props.children}
      {contentList.map((item, index) => (
        <Content key={index} item={item} />
      ))}
      <Images />
    </div>
  );
};

export default MapPopUp;
