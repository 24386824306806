import * as api from '../../services/mine.js';

const parseFloatNum = (num) => {
  return parseFloat(num / 10000).toFixed(2);
};
// 数据统计模块-数据统计
export const getDataStatistics = async () => {
  const { custNums, ...dataListRes } = await api.getDataStatistics();

  let list = [
    [
      { name: 'SAL.num', text: '矿山上线数', custType: 'SAL' },
      { name: '5', text: '营销服务商', custType: 'YX' },
      { name: '3', text: '终端用户', custType: 'BUY' },
      { name: '5', text: '物流服务商', custType: 'WL' },
      { name: dataListRes.shipNum, text: '船舶上线' },
      { name: dataListRes.carNum, text: '车辆' },
    ],
    [
      {
        name: parseFloatNum(dataListRes.todayDeliveryWeight),
        text: '今日成交量（万吨）',
      },
      {
        name: parseFloatNum(dataListRes.todayBillWeight),
        text: '今日运输量（万吨）',
      },
      {
        name: parseFloatNum(dataListRes.todayDeliveryAmount),
        text: '今日成交金额（万元）',
      },
      {
        name: parseFloatNum(dataListRes.totalDeliveryWeight),
        text: '累计成交量（万吨）',
      },
      {
        name: parseFloatNum(dataListRes.totalBillWeight),
        text: '累计运输量（万吨）',
      },
      {
        name: parseFloatNum(dataListRes.totalDeliveryAmount),
        text: '累计成交金额（万元）',
      },
    ],
  ];

  custNums.forEach((item, index) => {
    list[0].forEach((i) => {
      if (item.custType === i.custType) {
        i.name = item.num;
      }
    });
  });
  return list;
};

// 最新动态-数据处理
export const delivery = async () => {
  const { rows } = await api.getIndexDelivery();
  return rows;
};
// 船舶点位信息获取
/**
 *  {
    isSelect: false,
    position: [114.14905060742186, 22.294746889737134],
    region: 'Guangdong',
    name:'粤广州工0083',
    goods: '1~3碎石',
    weight: weight.weight,
    date: '2021-01-2',
    videoUrl: video1,
    imageUrls: [image1,image2,image3,image4],
  },
 */
export const wayBillRes = async () => {
  const res = await api.getWayBill();
  console.log('res',res);
  if (res.success) {
    console.log(res.result);
    let list = res.result.map((item, index) => {
      return {
        isSelect: index === 0,
        position: [item.longitude, item.latitude],
        region: item.province ==='广东省'?'Guangdong':'Guangxi',
        name: item.shipName,
        date: item.unloadTime,
        videoUrl: item.video,
        imageUrls: item.pictures,
        goods: item.varietyName,
        weight: item.quantity
      };
    });
    return list;
  }else {
    return []
  }

};
