import React from "react";
import TitleComponent from "@/components/title_component/index";
import Environmental from "./environmental";
import styles from "../style/index.module.scss";
import Energy from './energy'
import Video from './video'
import Weighbridge from './weighbridge'
import ConstructionSupervision from './constructionSupervision'
import MapPopUp from './map_pop'

export default function Home() {
  return (
    <div className={styles.home}>
      <TitleComponent
        title="智慧矿山大屏"
        rightText="云砼网数据大屏"
      />
      <div className={styles.main}>
        <div className={styles.mainLeft}>
            <Environmental/>
            <Energy />
        </div>
        <div className={styles.mainMiddle}>
          <MapPopUp />
          <ConstructionSupervision />
        </div>
        <div className={styles.mainRight}>
          <Video />
          <Weighbridge />
        </div>
      </div>
    </div>
  );
}
