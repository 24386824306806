import React from 'react';
import {
  HashRouter,
  Route,
  Redirect,
  Switch,
  BrowserRouter,
} from 'react-router-dom';
import Home from '../pages/Index';
import smartMine from '../pages/smart_mine';

class Router extends React.Component {
 
  render() {
    return (
      <HashRouter basename="/">
        <main>
          <Switch>
            <Route
              path="/"
              exact
              component={Home}
            />

            <Route
              path="/mine"
              exact
              component={smartMine}
            />
            <Redirect to="/" />
          </Switch>
        </main>
      </HashRouter>
    );
  }
}

export default Router;
