/**
 * 施工管理-数据
 */
import Mock from 'mockjs';
import url1 from '../assets/images/img/head_portrait/person_1.jpg';

const datafilter = (value) => {
  return value < 10 ? `0${value}` : value;
};

/**
 * 人员管理-数据
 * @param { name } 人员名称
 * @param { phone } 手机号
 * @param { job } 工种
 * @param { approachtime } 进场时间
 * @param { departureTime }  离场时间
 * @param { status }  在/离场状态
 * 数据生成规则： name， phone， job 提供数据
 * approachtime：进场时间：8:00-9:00 之间
 * departureTime：  15:00-18:00 之间
 */
const personnelList = [
  { name: '孙达', phone: '13173865088', job: '挖掘机驾驶员,' },
  { name: '蒋亚佳', phone: '18513764213', job: '安全员' },
  { name: '安子洲', phone: '18985224880', job: '挖掘机驾驶员' },
  { name: '蒋志江', phone: '13772680781', job: '挖掘机驾驶员' },
  { name: '李中颀', phone: '18576682060', job: '机械操作员' },
  { name: '陆显安', phone: '13338317778', job: '挖掘机驾驶员' },
  { name: '鲍伟华', phone: '18955374545', job: '机械操作员' },
  { name: '王之悦', phone: '13325805228', job: '电工' },
  { name: '赵铁炎', phone: '13592711385', job: '安全员' },
  { name: '薛德利', phone: '13147549799', job: '挖掘机驾驶员' },
  { name: '丁睿', phone: '13857735641', job: '挖掘机驾驶员' },
  { name: '沈豪', phone: '13943447914', job: '爆破工' },
  { name: '董望嘉', phone: '13936019179', job: '挖掘机驾驶员' },
  { name: '程健一', phone: '13326374626', job: '挖掘机驾驶员' },
  { name: '严钧', phone: '18968220290', job: '机械操作员' },
  { name: '许闱帷', phone: '18956473742', job: '挖掘机驾驶员' },
  { name: '黄希帅', phone: '18524645604', job: '机械操作员' },
  { name: '邵俊颖', phone: '13988679970', job: '机械操作员' },
  { name: '贾遥', phone: '18983748558', job: '挖掘机驾驶员' },
  { name: '王翔', phone: '18160620243', job: '机械操作员' },
  { name: '姚帅', phone: '13357477274', job: '电工' },
  { name: '陆蓓', phone: '13329642750', job: '挖掘机驾驶员' },
  { name: '许来萍', phone: '13345484187', job: '挖掘机驾驶员' },
  { name: '夏银', phone: '18535593699', job: '挖掘机驾驶员' },
  { name: '金晓华', phone: '13990327330', job: '机修工' },
  { name: '耿路兵', phone: '18698907047', job: '挖掘机驾驶员' },
  { name: '王子龙', phone: '18935803809', job: '爆破工' },
  { name: '董刚强', phone: '13756971830', job: '挖掘机驾驶员' },
  { name: '苏洪', phone: '14942163013', job: '电工' },
  { name: '孙毅', phone: '18585723867', job: '安全员' },
  { name: '赵世远', phone: '13398875988', job: '挖掘机驾驶员' },
  { name: '阮征', phone: '13768871799', job: '挖掘机驾驶员' },
  { name: '崔清泉', phone: '13987609204', job: '挖掘机驾驶员' },
  { name: '陈康康', phone: '13830021835', job: '挖掘机驾驶员' },
];

const urls = personnelList.map((item, index) =>
  require(`../assets/images/img/head_portrait/person_${index + 1}.jpg`)
);

const timeArr = new Array(34).fill('').map((item) =>
  Mock.mock({
    'minutes|0-59': 20,
    'second|0-59': 20,
  })
);
const timeArr2 = new Array(34).fill('').map((item) =>
  Mock.mock({
    'hour|15-17': 15,
    'minutes|0-59': 20,
    'second|0-59': 20,
    'status|0-1': 0,
  })
);

const personnelData = personnelList.map((item, index) => ({
  ...item,
  approachtime: `08:${datafilter(timeArr[index].minutes)}:${datafilter(
    timeArr[index].second
  )}`,
  departureTime: `${datafilter(timeArr2[index].hour)}:${datafilter(
    timeArr2[index].minutes
  )}:${datafilter(timeArr2[index].second)}`,
  status: timeArr2[index].status ? '离场' : '在场',
  headPortrait: urls[index].default,
}));

/**
 * 车辆管理数据
 * @param { CarLicense }车牌
 * @param { name } 人员名称
 * @param { phone } 手机号
 * @param { job } 工种
 * @param { approachtime } 进场时间
 * @param { departureTime }  离场时间
 * @param { status }  在/离场状态
 * * 数据生成规则： name， phone， job 提供数据
 * CarLicense：车牌 [桂D,桂J,桂K,桂M,桂L,桂H,桂A,桂G,桂B,桂C,粤W,粤H]+随机5位数编号/桂D的车牌号多排一些
 * approachtime：进场时间：8:00-16:00 之间
 * departureTime：  等于进场时间加上2至3小时之间随机数
 */

const timeAndNumArr = new Array(35).fill('').map((item) =>
  Mock.mock({
    'hour|8-15': 8,
    'minutes|0-59': 20,
    'second|0-59': 20,
    'number|10000-99999': 23322,
  })
);
const timeList = new Array(35).fill('').map((item) =>
  Mock.mock({
    'hour|11-18': 10,
    'minutes|0-59': 20,
    'second|0-59': 20,
    'status|0-1': 1,
  })
);
const carManagement = [
  { CarLicense: '桂G', name: '尹忠家', phone: '13156221196' },
  { CarLicense: '桂A', name: '赵艳伍', phone: '13554982135' },
  { CarLicense: '桂K', name: '吕昊', phone: '13952425436' },
  { CarLicense: '桂D', name: '付祥龙', phone: '18148863845' },
  { CarLicense: '桂J', name: '魏玮', phone: '18569347514' },
  { CarLicense: '桂B', name: '孟宁', phone: '18979222393' },
  { CarLicense: '桂D', name: '高晓俊', phone: '13834765438' },
  { CarLicense: '桂', name: '陈德龙', phone: '18636987977' },
  { CarLicense: '桂A', name: '白英', phone: '13760598724' },
  { CarLicense: '桂D', name: '张晓钧', phone: '13575867126' },
  { CarLicense: '桂J', name: '张佳铭', phone: '18617900884' },
  { CarLicense: '桂D', name: '李超文', phone: '13333919724' },
  { CarLicense: '桂A', name: '吉庆勇', phone: '13924024768' },
  { CarLicense: '桂D', name: '朱海华', phone: '13913812442' },
  { CarLicense: '桂G', name: '施忠新', phone: '13977429360' },
  { CarLicense: '桂H', name: '施海峰', phone: '18973251110' },
  { CarLicense: '桂B', name: '沈健辉', phone: '18688545208' },
  { CarLicense: '桂D', name: '刘铭', phone: '15195794728' },
  { CarLicense: '桂H', name: '代林', phone: '13190137979' },
  { CarLicense: '桂M', name: '方金贵', phone: '13364252986' },
  { CarLicense: '桂D', name: '高健维', phone: '18117637076' },
  { CarLicense: '桂L', name: '韩红德', phone: '13189177425' },
  { CarLicense: '桂C', name: '孔楼楼', phone: '13346144517' },
  { CarLicense: '粤W', name: '刘培彪', phone: '18558571708' },
  { CarLicense: '粤H', name: '吕芬', phone: '15197541510' },
  { CarLicense: '桂D', name: '邵俊颖', phone: '13720561163' },
  { CarLicense: '粤W', name: '沈俊', phone: '13880102037' },
  { CarLicense: '桂D', name: '宋占彪', phone: '13142698547' },
  { CarLicense: '桂K', name: '王震', phone: '18111677224' },
  { CarLicense: '桂D', name: '信阳阳', phone: '13893294362' },
  { CarLicense: '桂M', name: '薛金星', phone: '13530890057' },
  { CarLicense: '桂C', name: '杨尚', phone: '13926880646' },
  { CarLicense: '桂D', name: '于淼舟', phone: '13859832709' },
  { CarLicense: '粤H', name: '张忠良', phone: '18945690548' },
  { CarLicense: '桂L', name: '赵宏斌', phone: '13733682029' },
];
const carManagementData = carManagement.map((item, index) => ({
  ...item,
  CarLicense: item.CarLicense + timeAndNumArr[index].number,
  approachtime: `${datafilter(timeAndNumArr[index].hour)}:${datafilter(
    timeAndNumArr[index].minutes
  )}:${datafilter(timeAndNumArr[index].second)}`,
  departureTime: `${datafilter(timeAndNumArr[index].hour + 3)}:${datafilter(
    timeList[index].minutes
  )}:${datafilter(timeList[index].second)}`,
  status: timeList[index].status ? '离场' : '在场',
}));
export default {
  personnelData: personnelData,
  carManagement: carManagementData,
};
