/**
 * 【采砂监控】
 */

import React, { useRef } from 'react';
import Container from '@/components/container';
import styles from './index.module.scss';
import VideoPlayComponent from '@/components/video_component/video_play';
import videoUrl1 from '@/assets/images/videos/mining_video/nine_2.mp4';
import videoUrl2 from '@/assets/images/videos/mining_video/nine_3.mp4';
const Index = (props) => {
  const videoRef = useRef();
  const videoCinfig = {
    autoPlay: 'autoplay',
    loop: 'loop',
  };
  return (
    <Container
      text="采砂监控"
      showIsMore={false}
      className={styles.bgc}
      hasTitle={true}
    >
      <div className={styles.videoWrapper}>
        <VideoPlayComponent
          {...props}
          videoRef={videoRef}
          style={{
            width: `${399 / 192}rem`,
            height: `${230 / 192}rem`,
            padding: `${3 / 192}rem`,
          }}
          videoCinfig={videoCinfig}
          title="梧州市龙浩贸易有限公司思扶石场"
          videoUrl={videoUrl1}
        />
      </div>
      <div className={styles.videoWrapper}>
        <VideoPlayComponent
          {...props}
          videoRef={videoRef}
          style={{
            width: `${399 / 192}rem`,
            height: `${230 / 192}rem`,
            padding: `${3 / 192}rem`,
          }}
          videoCinfig={videoCinfig}
          title="梧州市龙圩区龙圩宕儿冲石场"
          videoUrl={videoUrl2}
        />
      </div>
    </Container>
  );
};

export default Index;
