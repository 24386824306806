/**
 * 【人员列表滚动逻辑】
 */

import React, { useRef, useState } from 'react';
import Personnel from './personnel.jsx';
import useInterval from '@/hooks/useInterval';
import { dpr } from '@/utils';

const PersonnelScroll = (props) => {
  const wrapperRef = useRef();
  const listRef = useRef();
  const [topDistance, setTopDistance] = useState(0);
  const [state, setState] = useState(true);

  useInterval(() => {
    setState(true);
    setTopDistance(topDistance - 87 * dpr);
    if (
      listRef.current.clientHeight + topDistance - 87 * dpr <
        wrapperRef.current.clientHeight ||
      listRef.current.clientHeight + topDistance ===
        wrapperRef.current.clientHeight
    ) {
      setState(false);
      setTopDistance(0);
      props.onChange(1)
    }
  }, 2000);

  return (
    <Personnel
      wrapperRef={wrapperRef}
      listRef={listRef}
      topDistance={topDistance}
      state={state}
      {...props}
    />
  );
};

export default PersonnelScroll;
