import React, { useState } from 'react';
import styles from './index.module.scss';
import StaffRecords from '@/components/staff_records';
const Personnel = (props) => {
  const [list, setlist] = useState([1, 2, 3, 4, 5, 6]);
  return (
    <div
      ref={props.wrapperRef}
      className={styles.scrollWrapper}
      style={{
        // left: props.active === 0? '0' : '-770px',
      }}
    >
      <div
        ref={props.listRef}
        className={styles.itemWrapper}
        style={{
          top: `${props.topDistance}px`,
          transition: props.state ? 'top 0.5s' : '',
        }}
      >
        {props.personnelData.map((item, index) => (
          <StaffRecords key={index} data={item} />
        ))}
      </div>
    </div>
  );
};

export default Personnel;
