import React, { useEffect, useState } from 'react';
import Map from '@/components/map';
import Home from './components/home';
import { mineMapData } from '../../mock/map_data';
import { withRouter } from 'react-router-dom';
export const SmartMineContext = React.createContext({});
let list = JSON.parse(JSON.stringify(mineMapData.slice(2,mineMapData.length-1)));
const SmartMine = () => {
  const [mapMarkList, setMapMarkList] = useState(mineMapData);


  // 获取地图当前的数据，回调函数
  const getCurrent = (list, index) => {
    setMapMarkList(list);
    // setCurrent(list[index].region);
  };
  document.title='智慧矿山大屏'
  return (
    <SmartMineContext.Provider value={{ getCurrent }}>
      <Map
        adcode={[450000]}
        markList={[...list,...mapMarkList]}
        size192={7}
        size384={8}
        maplocation={[107.98994640624997, 22.90103633470616]}
      >
        <Home />
      </Map>
    </SmartMineContext.Provider>
  );
};

export default withRouter(SmartMine);
