/**
 * 【地磅-当日记录】
 */
import React from 'react';
import BarGraph from '@/components/bar_graph';
import styles from './index.module.scss';
// import data from '@/mock/environment_data';
import data from '@/mock/weighbridge_data';
const Today = () => {
  const { weighbridgeData } = data;
  /**
   * 兼容双坐标刻度线不统一问题
   * @param {Arrary} arr 
   * @returns {Object} 
   */
  const getCoordinateSize = (arr,num) => {
    let arrMax =  Math.ceil(Math.max.apply(null, arr)/num)*num;
    let arrMin =  Math.ceil(Math.min.apply(null, arr)/num)*num;
    return {
      max: arrMax,
      min: arrMin,
      splitNumber: 5,
      interval: (arrMax - arrMin) / 5,
    };
  };
  return (
    <BarGraph
      className={styles.LineGraph}
      id="line-graph-Today"
      xAxisData={weighbridgeData.x}
      legendData={['出场车辆运砂量', '出场车辆']}
      yAxisName={['吨', '辆']}
      hasYAxis={true}
      seriesData={[[...weighbridgeData.y[1]], [...weighbridgeData.y[0]]]}
      seriesColor={['rgba(243,204,98,1', 'rgba(170,98,21,1)']}
      yAxisMinOrMax={[
        getCoordinateSize(weighbridgeData.y[0],1000),
        getCoordinateSize(weighbridgeData.y[1],10),
      ]}
      // type="single"
    />
  );
};

export default Today;
