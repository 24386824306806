/**
 * 【地图控件】
 * @param { adcode } Array 地图高亮区域列表支持【440000, 450000】
 * @param { markList } Array 地图覆盖物点位列表包含多个object
 * @param { center } Array 地图中心点位
 * @param { zoom } Number 缩放比例
 * @param { showSvgWest } Bool 当前是否显示广西
 * @param { showSvgEast } Bool 当前是否显示广东
 */
import React, { useRef, useEffect, useState } from 'react';
import iconBoatSelect from '@/assets/images/icon/icon_boat_select.png';
import iconBoat from '@/assets/images/icon/icon_boat.png';
import svg1 from '@/assets/images/svg/1.svg';
import svg2 from '@/assets/images/svg/2.svg';
import styles from './style/map.module.scss';
import { fontSize } from '../../utils/utils';

let clientWidth =
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;
let map = null;
let overlayGroups = null;
let markers = [];
const Map = (props) => {
  let [disProvince] = useState(null);
  let [colors] = useState({});
  const [routerChange, setRouterChange] = useState(false)
  const [markerList] = useState([
    {
      icon: iconBoat,
      isSelect: false,
      position: [109.22521984374997, 24.811451117262646],
    },
    {
      icon: iconBoat,
      isSelect: false,
      position: [109.59875499999997, 22.983461640416788],
    },
    {
      icon: iconBoat,
      isSelect: false,
      position: [111.88391124999997, 24.191640764366262],
    },
    {
      icon: iconBoat,
      isSelect: false,
      position: [112.96057140624997, 24.431929603768168],
    },
    {
      icon: iconBoat,
      isSelect: false,
      position: [114.01525890624997, 23.870550679689206],
    },
    {
      icon: iconBoat,
      isSelect: false,
      position: [112.45520031249997, 22.09050895723407],
    },
    {
      icon: iconBoat,
      isSelect: false,
      position: [111.00500499999997, 21.805186886104686],
    },
    {
      icon: iconBoatSelect,
      isSelect: false,
      position: [111.92785656249997, 22.942998757015342],
    },
  ]);
 
  useEffect(() => {
    if (!map ) {
      initMap();
    }
    setAreaHighlight(props.adcode ? props.adcode : [440000, 450000]);
    if (!document.querySelector('.amap-logo')) return;
    setTimeout(() => {
      document.querySelector('.amap-logo').style.opacity = '0';
    }, 0);

    return ()=> {
      map = null
    }
  },[]);
  useEffect(() => {
    setMarker(props.markList ? props.markList : markerList);
  }, [props.markList]);
  //初始化地图
  const initMap = () => {
    let zoom = clientWidth <= 1920 ? props.size192 || 6 : props.size384 || 7;
    map = new window.AMap.Map('map', {
      resizeEnable: true, //是否监控地图容器尺寸变化
      mapStyle: 'amap://styles/6aa917e7465f38f98e2e93dde1ad1ebb',
      showLabel: false,
      zoom: props.zoom ? props.zoom : zoom,
      center: props.center
        ? props.center
        : props.maplocation || [113.88994640624997, 22.90103633470616],
      // : [107.98994640624997, 22.90103633470616],
    });
  };
  //设置地图区域选中高亮
  const setAreaHighlight = (data) => {
    disProvince = new window.AMap.DistrictLayer.Province({
      zIndex: 12,
      adcode: data,
      depth: 1,
      styles: {
        fill: function (properties) {
          let adcode = properties.adcode;
          return getColorByAdcode(adcode);
        },
        'province-stroke': '', //设置省级边界
        'city-stroke': '#000000', // 中国地级市边界
        'county-stroke': 'rgba(255,255,255,1)', // 中国区县边界
      },
    });
    disProvince.setMap(map);
  };
  //设置标记点
  const setMarker = (data) => {
    if (map && overlayGroups) {
      map.remove(overlayGroups);
    }
    markers = [];
    data.forEach((item) => {
      markers.push(
        new window.AMap.Marker({
          isTop:item.isSelect,
          icon: new window.AMap.Icon({
            image: item.isSelect ? iconBoatSelect : iconBoat,
            size: new window.AMap.Size(fontSize(45), fontSize(45)), //图标所处区域大小
            imageSize: new window.AMap.Size(fontSize(45), fontSize(45)), //图标大小
          }),
          
          position: item.position,
        })
      );
    });
    overlayGroups = new window.AMap.OverlayGroup(markers);
    map.add(overlayGroups);
  };
  const getColorByAdcode = function (adcode) {
    if (440000 <= adcode && adcode < 450000) {
      colors[adcode] = '#5A493A';
    } else {
      colors[adcode] = '#90745C';
    }
    return colors[adcode];
  };
  return (
    <div className={styles.map}>
      {props.showSvgWest ? (
        <img
          className={styles.svg1}
          src={svg1}
          alt=""
          style={{ display: props.showSvgWest ? 'blcok' : 'none' }}
        />
      ) : (
        <img
          className={styles.svg2}
          src={svg2}
          alt=""
          style={{ display: props.showSvgEast ? 'blcok' : 'none' }}
        />
      )}

      <div id="map" className={styles.container}></div>
      <div>{props.children}</div>
    </div>
  );
};

export default Map
