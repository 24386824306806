/**
 * 【地图点位数据及信息】
 *
 */
import Mock from 'mockjs';
import video1 from '@/assets/images/videos/boats_video/boat_1.mp4'
import video2 from '@/assets/images/videos/boats_video/boat_2.mp4'
import image1 from '@/assets/images/img/boats_img/boat_1.jpg'
import image2 from '@/assets/images/img/boats_img/boat_2.jpg'
import image3 from '@/assets/images/img/boats_img/boat_3.jpg'
import image4 from '@/assets/images/img/boats_img/boat_4.jpg'
import image5 from '@/assets/images/img/boats_img/boat_5.jpg'
import image6 from '@/assets/images/img/boats_img/boat_6.jpg'
import ww from '../assets/images/img/mining_img/ mine_1.jpg'
const urls = new Array(26).fill('').map((item,index) => require(`../assets/images/img/mining_img/ mine_${index+1}.jpg`))
const urlList = urls.map((item)=>item.default)
const weight = Mock.mock({
  'weight|2000-5000': 2000,
})
export const mapMarkList = [
  {
    isSelect: false,
    position: [114.14905060742186, 22.294746889737134],
    region: 'Guangdong',
    name:'粤广州工0083',
    goods: '1~3碎石',
    weight: weight.weight,
    date: '2021-01-2',
    videoUrl: video1,
    imageUrls: [image1,image2,image3,image4],
  },
  {
    isSelect: false,
    position: [113.44592560742186, 22.46744549825894],
    region: 'Guangdong',
    name:'新港湾66',
    goods: '机制砂',
    weight: weight.weight,
    date: '2021-01-10',
    videoUrl: video2,
    imageUrls: [image5,image6,image1,image2],
  },
  {
    isSelect: false,
    position: [113.51184357617186, 22.70075409884148],
    region: 'Guangdong',
    name:'客德庆工333',
    goods: '石粉',
    weight: weight.weight,
    date: '2021-01-15',
    videoUrl: video2,
    imageUrls: [image3,image4,image5,image6],
  },
  {
    isSelect: false,
    position: [113.51184357617186, 22.70075409884148],
    region: 'Guangdong',
    name:'HONG XIANG ZUAN',
    goods: '石粉',
    weight: weight.weight,
    date: '2021-01-18',
    videoUrl: video1,
    imageUrls: [image1,image2,image3,image4],
  },
  {
    isSelect: false,
    position: [113.80847443554686, 22.81219641996333],
    region: 'Guangdong',
    name:'粤广州工2018',
    goods: '石粉',
    weight: weight.weight,
    date: '2021-01-19',
    videoUrl: video2,
    imageUrls: [image5,image6,image1,image2],
  },
  {
    isSelect: false,
    position: [113.98425568554686, 23.176277989258043],
    region: 'Guangdong',
    name:'新港湾68',
    goods: '石粉',
    weight: weight.weight,
    date: '2021-01-21',
    videoUrl: video1,
    imageUrls: [image3,image4,image5,image6],
  },
  {
    isSelect: false,
    position: [113.12732209179686, 23.448691603779025],
    region: 'Guangdong',
    name:'新港湾62',
    goods: '石粉',
    weight: weight.weight,
    date: '2021-01-22',
    videoUrl: video2,
    imageUrls: [image1,image2,image3,image4],
  },
  {
    isSelect: false,
    position: [113.12732209179686, 23.448691603779025],
    region: 'Guangdong',
    name:'2596',
    goods: '石粉',
    weight: weight.weight,
    date: '2021-01-11',
    videoUrl: video1,
    imageUrls: [image5,image6,image1,image2],
  },
  {
    isSelect: false,
    position: [112.11657990429686, 22.903308758429574],
    region: 'Guangdong',
    name:'世海6699',
    goods: '石粉',
    weight: weight.weight,
    date: '2021-01-09',
    videoUrl: video2,
    imageUrls: [image3,image4,image5,image6],
  },
  {
    isSelect: true,
    position: [111.61120881054686, 23.86128303957129],
    region: 'Guangdong',
    name:'金达288',
    goods: '石粉',
    weight: weight.weight,
    date: '2021-01-13',
    videoUrl: video1,
    imageUrls: [image1,image2,image3,image4],
  },
  {
    isSelect: false,
    position: [111.23767365429686, 23.559514480579825],
    region: 'Guangxi',
    name:'贵港富航838',
    goods: '石粉',
    weight: weight.weight,
    date: '2021-01-03',
    videoUrl: video2,
    imageUrls: [image5,image6,image1,image2],
  },
  {
    isSelect: false,
    position: [110.96301545117186, 23.337775672334356],
    region: 'Guangxi',
    name:'桂桂平货6189',
    goods: '石粉',
    weight: weight.weight,
    date: '2021-01-04',
    videoUrl: video1,
    imageUrls: [image3,image4,image5,image6],
  },
  {
    isSelect: false,
    position: [109.35901154492186, 23.075242788940308],
    region: 'Guangxi',
    name:'YANGPUHENGHAI0233',
    goods: '石粉',
    weight: weight.weight,
    date: '2021-01-06',
    videoUrl: video2,
    imageUrls: [image1,image2,image3,image4],
  },
  {
    isSelect: false,
    position: [108.55700959179686, 24.082134587205424],
    region: 'Guangxi',
    name:'LINGNANGONG6',
    goods: '石粉',
    weight: weight.weight,
    date: '2021-01-07',
    videoUrl: video1,
    imageUrls: [image5,image6,image1,image2],
  },
];
/**
 * 梧州市龙浩贸易有限公司思扶石场，梧州市龙圩区龙圩宕儿冲石场
 */
export const mineMapData = [
  {
    isSelect: true,
    position:  [111.10162533203122, 22.68188973147319],
    name:'梧州市龙圩区龙圩宕儿冲石场',
    personnelTotal: 424,
    carTotal: 173,
    imageUrls: [urlList[13],urlList[14],urlList[15],urlList[16]],
  },
  {
    isSelect: false,
    position: [111.10162533203122, 22.904902124507004],
    name:'梧州市龙浩贸易有限公司思扶石场',
    personnelTotal: 366,
    carTotal: 125,
    imageUrls: [urlList[17],urlList[18],urlList[19],urlList[20]],
  },
  {
    isSelect: false,
    position: [107.30102625, 24.369509813798185],
    name:'梧州市龙浩贸易有限公司思扶石场',
    personnelTotal: 473,
    carTotal: 206,
    imageUrls: [urlList[0],urlList[2],urlList[3],urlList[4]],
  },
  {
    isSelect: false,
    position:  [110.64087, 25.56463810563745],
    name:'梧州市龙圩区龙圩宕儿冲石场',
    personnelTotal: 129,
    carTotal: 35,
    imageUrls: [urlList[5],urlList[6],urlList[7],urlList[8]],
  },
  {
    isSelect: false,
    position: [109.322510625, 22.758346502814177],
    name:'梧州市龙浩贸易有限公司思扶石场',
    personnelTotal: 207,
    carTotal: 80,
    imageUrls: [urlList[9],urlList[10],urlList[11],urlList[12]],
  },
  {
    isSelect: false,
    position:  [111.10162533203122, 22.68188973147319],
    name:'梧州市龙圩区龙圩宕儿冲石场',
    personnelTotal: 424,
    carTotal: 173,
    imageUrls: [urlList[13],urlList[14],urlList[15],urlList[16]],
  },
  {
    isSelect: false,
    position: [111.10162533203122, 22.904902124507004],
    name:'梧州市龙浩贸易有限公司思扶石场',
    personnelTotal: 366,
    carTotal: 125,
    imageUrls: [urlList[17],urlList[18],urlList[19],urlList[20]],
  },
  {
    isSelect: false,
    position: [109.90162533203122, 22.904902124507004],
    name:'梧州市龙圩区龙圩宕儿冲石场',
    personnelTotal: 181,
    carTotal: 76,
    imageUrls: [urlList[21],urlList[22],urlList[23],urlList[24]],
  },
  {
    isSelect: false,
    position: [108.10162533203122, 22.604902124507004],
    name:'梧州市龙浩贸易有限公司思扶石场',
    personnelTotal: 337,
    carTotal: 147,
    imageUrls: [urlList[25],urlList[26],urlList[0],urlList[1]],
  },
  {
    isSelect: false,
    position: [108.90102625, 23.369509813798185],
    name:'梧州市龙圩区龙圩宕儿冲石场',
    personnelTotal: 272,
    carTotal: 93,
    imageUrls: [urlList[2],urlList[3],urlList[4],urlList[5]],
  },
  {
    isSelect: false,
    position: [110.33102625, 22.969509813798185],
    name:'梧州市龙浩贸易有限公司思扶石场',
    personnelTotal: 367,
    carTotal: 192,
    imageUrls: [urlList[6],urlList[7],urlList[8],urlList[9]],
  },
  {
    isSelect: false,
    position: [109.70102625, 24.969509813798185],
    name:'梧州市龙圩区龙圩宕儿冲石场',
    personnelTotal: 408,
    carTotal: 168,
    imageUrls: [urlList[10],urlList[11],urlList[12],urlList[13]]
  },

]
