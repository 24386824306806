/**
 * 【地磅记录】
 */
import React, { useState, useEffect } from 'react';
import Container from '@/components/container';
import styles from './index.module.scss';
import Today from './today';
import Tabs from '@/components/tab';
import { Carousel } from 'antd-mobile';
import Month from './month';
import data from '@/mock/weighbridge_data';
const { weighbridgeMonthDataTotal, weighbridgeData } = data;

const Index = () => {
  const [active, setActive] = useState(0);
  const [todaycarNum, setTodaycarNum] = useState(0);
  const [todayWeight, setTodayWeight] = useState(0);
  const [monthcarNum, setMonthcarNum] = useState(0);
  const [monthWeight, setMonthWeight] = useState(0);
  const afterChange = (e) => {
    setActive(e);
  };

  useEffect(() => {
    setTodaycarNum(getTotal(weighbridgeData.y[1]));
    setTodayWeight(getTotal(weighbridgeData.y[0]));
    setMonthcarNum(getTotal(weighbridgeMonthDataTotal.count));
    setMonthWeight(getTotal(weighbridgeMonthDataTotal.weight));
  }, []);

  const getTotal = (arr) => {
    return arr.reduce((a, b) => a + b);
  };
  return (
    <div className={styles.weighbridge}>
      <Container
        text="地磅记录"
        showIsMore={false}
        className={styles.bgc}
        hasTitle={true}
      >
        <Tabs
          list={['当日逐时', '近一月']}
          active={active}
        />
        {/* {active===0 ? (
          <div className={styles.total}>
            <p>{`今日累计出场车辆：${todaycarNum}辆`}</p>
            <p>{`逐时出场车辆运砂量:${todayWeight}吨`}</p>
          </div>
        ) : (
          <div className={styles.total}>
            <p>{`近一月累计出场车辆：${monthcarNum}辆`}</p>
            <p>{`近一月出场车辆运砂量:${monthWeight}吨`}</p>
          </div>
        )} */}
        <Carousel
          autoplay={true}
          autoplayInterval={5000}
          infinite
          dots={false}
          afterChange={afterChange}
        >
          <Today />
          <Month />
        </Carousel>
      </Container>
    </div>
  );
};

export default Index;
