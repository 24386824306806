/**
 * 【能耗监测】
 */
import React, { useState} from 'react';
import Container from '@/components/container';
import Tabs from '@/components/tab';
import styles from './style/index.module.scss';
import { Carousel } from 'antd-mobile';
import FuelOil from './fuelOil';
import Discharge from './discharge'
import PowerConsumption from './powerConsumption'

const Energy = (props) => {
  const [active, setActive] = useState(0)
  
  const afterChange = (e) => {
    setActive(e)
  }
  return (
    <Container
      text="能耗监测"
      showIsMore={false}
      className={styles.bgc}
      hasTitle={true}
    >
       <Tabs
        list={['燃油耗量', '排放量', '电量']}
        // onChange={onChange}
        active={active}
      />
      <Carousel
        autoplay={true}
        autoplayInterval={5000}
        infinite
        dots={false}
        afterChange={afterChange}
      >
        <FuelOil />
        <Discharge />
        <PowerConsumption />
      </Carousel>
      {/* <Tab list={[1,2,3,3]}/> */}
     
    </Container>
  );
};
export default Energy;
