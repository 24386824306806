/**
 * 【建材产业互联网数据大屏-首页】
 */
import React, { useState, useEffect } from 'react';
import Home from './components/home';
import Map from '@/components/map';
import * as getData from './getData';
import * as api from '../../services/mine.js';
import useInterval from '@/hooks/useInterval';
import { useHistory } from 'react-router-dom';
export const IndexContext = React.createContext({});

const Index = () => {
  const history = useHistory()
  const [markList, mapMarkList] = useState([]);
  const [current, setCurrent] = useState('Guangdong');
  const [exponentData, setExponentData] = useState(); // 水泥指数
  const [weaterCondition, setWeaterCondition] = useState(); // 水泥指数
  const [videoList, setVideoList] = useState([]); // 矿山实时采矿视频
  const [wayBill, setWayBill] = useState([]); // 船舶实时运输动态
  const [dataList, setDataList] = useState([[], []]); // 数据统计模块数据
  const [newsList, setNewsList] = useState([]); // 数据统计模块数据

  // 获取地图当前的数据，回调函数
  const getCurrent = (list, index) => {
    mapMarkList(list);
    setCurrent(list[index].region);
  };

  useEffect(async () => {
    // 获取水泥指数
    const res = await api.getExponent();
    setExponentData(res);

    // 获取水情数据
    const weaterConditionRes = await api.getWeaterCondition();
    const weaterConditionData = weaterConditionRes
      ? Object.values(weaterConditionRes).map((item, index) => ({
          ...item,
          key: index,
        }))
      : [];
    setWeaterCondition(weaterConditionData);
    // 获取数据统计-数据
    setDataList([...await getData.getDataStatistics()]);
    // 获取最新动态-数据
    setNewsList([...await getData.delivery()]);

    // 获取-矿山实时采矿视频
    const videoRes = await api.getSalVideo();
    if (videoRes.success) {
      setVideoList([...videoRes.urls]);
    }

    // 获取-船舶实时运输动态
    setWayBill([...await getData.wayBillRes()])

    document.title = '云砼网数据大屏';
  }, []);

  // 一个小时自动刷新一次
  useInterval(()=>{
    window.location.reload()
  },3600000)

  const dataConfig = {
    dataList,
    getCurrent,
    exponentData,
    weaterCondition,
    videoList,
    newsList,
    wayBill
  };

  return (
    <IndexContext.Provider value={dataConfig}>
      <Map
        // adcode={[450000]}
        // zoom={6}
        showSvgEast={current === 'Guangxi'}
        showSvgWest={current === 'Guangdong'}
        markList={markList}
      >
        <Home />
      </Map>
    </IndexContext.Provider>
  );
};
export default Index;
