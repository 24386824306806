/**
 * 【地磅-近一月记录】
 */
import React from 'react';
import BarGraph from '@/components/bar_graph';
import styles from './index.module.scss';
// import data from '@/mock/environment_data';
import data from '@/mock/weighbridge_data';
const Month = () => {
  const { weighbridgeMonthData } = data;
    /**
   * 兼容双坐标刻度线不统一问题
   * @param {Arrary} arr 
   * @returns {Object} 
   */
  const getCoordinateSize = (arr,num) => {
    let arrMax =  Math.ceil(Math.max.apply(null, arr));
    let arrMin =  Math.ceil(Math.min.apply(null, arr));
    return {
      max: arrMax,
      min: 0,
      splitNumber: 5,
      interval: (arrMax - 0) / 5,
    };
  };
  return (
    <BarGraph
      className={styles.LineGraph}
      id="line-graph-Month"
      xAxisData={weighbridgeMonthData.x}
      legendData={['出场车辆运砂量', '出场车辆']}
      yAxisName={['万吨', '万辆']}
      hasYAxis={true}
      seriesData={[
        [...weighbridgeMonthData.y.count],
        [...weighbridgeMonthData.y.weight]
      ]}
      yAxisMinOrMax={[
        getCoordinateSize(weighbridgeMonthData.y.weight,10000),
        getCoordinateSize(weighbridgeMonthData.y.count,10000),
      ]}
      seriesColor={['rgba(243,204,98,1', 'rgba(170,98,21,1)']}
      // type="single"
    />
  );
};

export default Month;
