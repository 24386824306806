/**
 * 【排水水质】
 */
import React from 'react';
import BarGraph from '@/components/bar_graph';
import styles from './style/environmental.module.scss';
import data from '@/mock/environment_data';
import {dpr } from '@/utils'
const WaterQuality = () => {
  const { waterQuality } = data;
  return (
    <BarGraph
      className={styles.LineGraph}
      id="line-graph"
      xAxisData={waterQuality.x}
      legendData={['悬浮物SS', '酸碱度PH']}
      yAxisName={['ph', 'ss']}
      hasYAxis={true}
      // legendData={data ? data.legendData : []}
      seriesData={[[...waterQuality.ss], [...waterQuality.ph]]}
      seriesColor={['rgba(243,204,98,1', 'rgba(170,98,21,1)']}
      // type="single"
      // xAxisData={data ? data.xAxisData : []}
    />
  );
};

export default WaterQuality;
