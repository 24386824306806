/**
 * 【沙石走势图】
 */
import React, { useState, useEffect } from 'react';
import Lines from './Lines';
import styles from './index.module.scss';
import Tabs from '@/components/tab';
import LittleTab from '@/components/tab/little_tab';
import { Carousel } from 'antd-mobile';
import {getPriceTrend} from "../../../../services/mine";
import useInterval from '@/hooks/useInterval'
let allData = []
let num = 0
const Index = () => {
    const [data, setData] = useState();
    // const [num, setNum] = useState(0);
    const [currentData, setCurrentData] = useState();

    const [active, setActive] = useState(0);
    const [tabactive, setTabactive] = useState(0);
    useEffect(async () => {
        let res = await getPriceTrend();
        let tabList = res.data.map((item, index) => item.name+'砂石价格走势图');
        let seriesData = []
        let legendData = []
        let xAxisData = [];
        let types = [];
        res.data.forEach((e, i) => {
            xAxisData = e.x;
            xAxisData = xAxisData.map((item, index) => {
                return item.substring(2,4)+"年"+item.substring(5,7)+"月"
            })
            e.data.forEach((j, k) => {
                seriesData = j.data.map((item, index) => item.data);
                legendData = j.data.map((item, index) => item.name);
                if(i === 0){
                    types.push(j.name)
                }
                allData.push({
                    yAxisName: j.name,
                    seriesData: seriesData,
                    legendData: legendData,
                    xAxisData: xAxisData
                })
            })
        })
        // console.log(1,tabList)
        // console.log(3,types)
        // console.log(2,allData)
        // console.log(0,num)
        setCurrentData(allData[num])

        setData({
            tabList: [...tabList],
            types: [...types],
        });
        return () => {
            setData()
        };
    }, []);

    useEffect(() => {
    }, [currentData])
    const onChange = (index) => {
        if (index === 0) {
            num = 0
        } else {
            num = data.types.length
        }
        setTabactive(num);
        setActive(index);
        setCurrentData(allData[num])
    };
    const onSubChange = (index) => {
        if (num < data.types.length) {
            num = Number(index)
        } else {
            num = data.types.length + Number(index)
        }
        setTabactive(index);
        setCurrentData(allData[num])
    };


    useInterval(() => {
        // console.log(0,num)
        // console.log(allData)
        if (num === allData.length - 1) {
            num=0
        } else {
            num=num+1
        }
        setActive(num<3 ? 0 : 1);
        setCurrentData(allData[num])
        setTabactive(data.types.indexOf(allData[num].yAxisName));
        // console.log(111,num,allData[num])
    }, 5000)
    return data?(
        <div className={styles.nationwide}>
            <div className={styles.tabs}>
                <Tabs
                    list={data?data.tabList:[]}
                    onChange={onChange}
                    active={active}
                />
            </div>
            <div className={styles.littleTabs}>
                <LittleTab
                    list={data?data.types:[]}
                    onChange={onSubChange}
                    active={tabactive}
                />
            </div>
            <Lines data={currentData} />
            <div className={styles.dashed}></div>
        </div>
    ):'';
};

export default Index;
