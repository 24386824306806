/**
 * 【最新水情-模块】
 */
import React from 'react';
import styles from './index.module.scss';
import Container from '@/components/container';
import Table from '@/components/table_component';
import icon from '@/assets/images/icon/icon_chart.png';

const columns = [
  {
    title: '站名',
    dataIndex: 'zm',
    key: 'zm',
    width: 100,
  },
  {
    title: '河名',
    dataIndex: 'hm',
    key: 'hm',
    width: 80,
  },
  {
    title: '时间',
    dataIndex: 'sj',
    key: 'sj',
    // width:90,
  },
  {
    title: '水位（m）',
    dataIndex: 'sw',
    key: 'sw',
    width: 80,
  },
  {
    title: '水位曲线',
    width: 80,
    render: () => <img src={icon} className={styles.curveIcon}/>,
  },
];
const Index = (props) => {
  return (
    <div className={styles.water}>
      <Container
        text="最新水情"
        showIsMore={true}
        className={styles.bgc}
        hasTitle={true}
      >
        <div className={styles.tableWrapper}>
          <Table
            columns={columns}
            dataSource={props.weaterCondition ? props.weaterCondition : []}
            scorll={{ y: '0.75rem' }}
            {...props}
          />
        </div>
      </Container>
    </div>
  );
};

export default Index;
