/**
 * 【table-滚动逻辑处理】
 */
import React, { useRef, useEffect, useState } from 'react';
import Table from './index';
import useInterval from '@/hooks/useInterval';
import { dpr } from '@/utils';
const TableScroll = (props) => {
  const [num, setNum] = useState(0);
  const tableRef = useRef();
  const tableListRef = useRef();
  useInterval(() => {
    setNum(num + 48 * dpr);
  }, 2000);

  useEffect(() => {
    tableRef.current.scrollTop = num;
    if (
      tableListRef.current.clientHeight - num < tableRef.current.clientHeight &&
      num > 0
    ) {
      props.onChange(0)
      
      setNum(0);

    }
  }, [num]);

  return (
    <Table
      {...props}
      tableRef={tableRef}
      tableListRef={tableListRef}
      scrollNum={num}
    />
  );
};

export default TableScroll;
