/**
 * 【空气质量】
 */
import React from 'react';
import styles from './style/environmental.module.scss';
import data from '@/mock/environment_data';
import LineGraph from '@/components/line_graph';
import { getQweather24h } from '@/services/mine';

const AirQuality = () => {
  const { airQuality } = data;
  return (
    <LineGraph
      className={styles.LineGraph}
      id="line1-graph-AirQuality"
      legendData={['pm2.5', 'pm10']}
      seriesData={airQuality.y}
      xAxisData={[...airQuality.x]}
      isMore={true}
      seriesColor={['rgba(170,98,21,1)', 'rgba(54,207,201,1)']}
      hasYAxis={true}
      yAxisName='单位：μg'
    />
  );
};

export default AirQuality;
