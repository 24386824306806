/**
 * 【广西-沙石走势图】
 */
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import LineGraphFitting from '@/components/line_graph/index_fitting';
import { getPriceTrend } from '../../../../services/mine.js';
const Index = (props) => {
  const [data, setData] = useState();

     // console.log(55,props.data)
  useEffect( () => {
    setData({
        ...props.data
    });
    // console.log(555,data)
    return () => {
      setData();
    };
  }, []);
  return data ? (
    <LineGraphFitting
      className={styles.LineGraph}
      id="line1-graph"
      hasYAxis={false}
      {...props.data}
      isMore={true}
      seriesColor={[
        'rgba(170,98,21,1)',
        'rgba(54,207,201,1)',
        'rgba(243,204,98,1)',
      ]}
    />
  ) : (
    ''
  );
};

export default Index;
