/**
 * 【内容区域】
 * @param {props} object
 * @param { className } String 可控className
 * @param { text } String title文字
 * @param { hasTitle } bool 是否含有title
 */
import React from "react";
import styles from "./style/container.module.scss";
import title_bg from "@/assets/images/title_bg.png";
import icon_more from "@/assets/images/icon_more.png";
export default function Container(props) {
  return (
    <div
      className={`${styles.container} ${
        props.className ? props.className : ""
      }`}
    >
      {props.hasTitle ? (
        <div className={styles.title}>
          <img src={title_bg} alt="title_bg" />
          <span className={styles.text}>{props.text || ""}</span>
          {props.showIsMore ? (
            <span className={styles.more}>
              <img src={icon_more} alt="icon_more" />
            </span>
          ) : null}
        </div>
      ) : null}

      <div className={styles.dashed}></div>
      {props.children}
    </div>
  );
}
