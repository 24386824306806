/**
 * 【tab功能】
 * @param {props} object
 * @param { list } Array tab列表
 */
import React, { useState, useEffect } from 'react';
import styles from './style/little_tab.module.scss';
export default function Tab(props) {
  const [activeItem, setActiveItem] = useState(0);
  const activeTab = (index) => {
    setActiveItem(index);
    // props.onChange(index);
  };
  useEffect(() => {
    setActiveItem(props.active || 0);
  }, [props]);
  return (
    <div className={styles.scrollWarpper}>
      <div className={styles.tabList}>
        {props.list.map((item, index) => (
          <span
            className={`tab-item ${index === activeItem ? styles.active : ''}`}
            key={index}
            // onClick={() => activeTab(index)}
          >
            {item}
          </span>
        ))}
      </div>
    </div>
  );
}
