/**
 * 【天气】
 */
import React from 'react';
import styles from './index.module.scss';
import positionIcon from '@/assets/images/icon/icon_position.png';

const Weater = (props) => {
  const data = props.data ? props.data[0] : null;
  return (
    <div className={styles.weaterWrapper}>
      <div className={styles.weater}>
        <div className={styles.positionIcon}>
          <img src={positionIcon} alt="" />
        </div>
        <div className={styles.position}>{data && data.city}</div>
        <div className={styles.weaterStatus}>
          {data && data.casts[0].dayweather}
        </div>
        <span className={styles.line}>｜</span>
        <div className={styles.temperature}>
          {data &&`${data.casts[0].nighttemp}℃ ~ ${data.casts[0].daytemp}℃`}
        </div>
      </div>
    </div>
  );
};

export default Weater;
