/**
 * 【云砼网数据大屏-首页】
 */
import React from 'react';
import styles from '../index.module.scss';
import TitleComponent from '@/components/title_component/index';
import Exponent from './exponent';
import Statistics from './statistics';
import WaterTable from './water/table_scroll';
import QueueUp from './queue_up/queue_up_scroll';
import Videos from './video/video_play';
import News from './news';
import TendencyEcharts from './tendency_echarts';
import MapPopUp from './map_pop';


const Home = () => {
  // const data = useContext(IndexContext);

  return (
    <div className={styles.home}>
      <TitleComponent
        title="云砼网数据大屏"
        rightText="智慧矿山大屏"
      />
      <div className={styles.main}>
        <div className={styles.mainLeft}>
          <Exponent />
          <News />
          <TendencyEcharts />
          {/* <LineGraph  className={styles.LineGraph} id="line1-graph"/> */}
          {/* <StaffRecords/> */}
        </div>
        <div className={styles.mainMiddle}>
          <MapPopUp />
          <Videos />
        </div>
        <div className={styles.mainRight}>
          <Statistics />
          <WaterTable />
          <QueueUp />
        </div>
      </div>
    </div>
  );
};

export default Home;
