/**
 * 【table-滚动逻辑处理】
 */
import React, { useRef, useEffect, useState, useContext } from 'react';
import Table from './index';
import useInterval from '@/hooks/useInterval';
import { dpr } from '@/utils';
import { IndexContext } from '../../index';
const TableScroll = (props) => {
  const { weaterCondition } = useContext(IndexContext);
  const [num, setNum] = useState(0);
  const tableRef = useRef();
  const tableListRef = useRef();
  useInterval(() => {
    setNum(num + 48 * dpr);
  }, 5000);

  useEffect(() => {
    tableRef.current.scrollTop = num;
    // console.log(tableListRef.current.clientHeight);
    // console.log('剩下', tableListRef.current.clientHeight - num);
    // console.log('可视区', tableRef.current.clientHeight);
    // console.log(num);
    if (
      tableListRef.current.clientHeight - num < tableRef.current.clientHeight &&
      num > 0
    ) {
      setNum(0);
    }
  }, [num]);

  return (
    <Table
      {...props}
      tableRef={tableRef}
      tableListRef={tableListRef}
      weaterCondition={weaterCondition}
      scrollNum={num}
    />
  );
};

export default TableScroll;
