/**
 * 【视频组件】
 * 支持配置封面
 * @param {props} object
 * @param {isshowtitle} string 是否显示title 默认不显示
 * @param {videoCinfig} video 属性自定义
 * videoCinfig:{
      autoPlay:'autoplay',
      loop: 'loop'
    }
 * style={{width: 414, padding:5}} 宽度，pandding 可以自定义
 * title={'某某矿山'}
 */
import React from 'react';
import styles from './index.module.scss';
import VideoTitle from './video_title';
// import videoUrl from '../../assets/images/videos/mining_video/nine_1.mp4';
import playIcon from '@/assets/images/icon/icon_play.png';
const VideoComponent = (props) => {
  return (
    <div className={styles.videoWrapper} style={props.style}>
      { !props.playBtnStatus && !props.loadingBtnStatus &&
      <div className={styles.playBtrn} onClick={props.playHandle} >
        <img src={playIcon} alt="" style={props.playIconSize}/>
      </div>
      }
      { props.loadingBtnStatus &&
      <div className={styles.playBtrn} style={{backgroundColor: 'transparent'}}>
          <div className={styles.skfadingcircle}>
              <div className={`${styles.skcircle1} ${styles.skcircle}`}></div>
              <div className={`${styles.skcircle2} ${styles.skcircle}`}></div>
              <div className={`${styles.skcircle3} ${styles.skcircle}`}></div>
              <div className={`${styles.skcircle4} ${styles.skcircle}`}></div>
              <div className={`${styles.skcircle5} ${styles.skcircle}`}></div>
              <div className={`${styles.skcircle6} ${styles.skcircle}`}></div>
              <div className={`${styles.skcircle7} ${styles.skcircle}`}></div>
              <div className={`${styles.skcircle8} ${styles.skcircle}`}></div>
              <div className={`${styles.skcircle9} ${styles.skcircle}`}></div>
              <div className={`${styles.skcircle10} ${styles.skcircle}`}></div>
              <div className={`${styles.skcircle11} ${styles.skcircle}`}></div>
              <div className={`${styles.skcircle12} ${styles.skcircle}`}></div>
          </div>
      </div>
      }
      <video muted ref={props.videoRef} src={props.videoUrl}  {...props.videoCinfig} ></video>
      {props.title && <VideoTitle title={props.title}/>}
    </div>
  );
};

export default VideoComponent;
