/**
 * 【数据统计-模块】
 */
import React, { useContext } from 'react';
import Statistics from '@/components/data_statistics';
import { IndexContext } from '../../index';
import Container from '@/components/container';
import styles from './index.module.scss';
import { Carousel } from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.css';
const Index = () => {
  const { dataList } = useContext(IndexContext);
  return (
    <div className={styles.statistics}>
      <Container text="数据统计" className={styles.bgc} hasTitle={true}>
        <Carousel
          autoplay={true}
          autoplayInterval={5000}
          infinite
          dots
          dotStyle={{ opacity: '0.3' }}
          dotActiveStyle={{ backgroundColor: '#fff' }}
        >
          {dataList.map((item, index) => (
            <Statistics dataList={item} key={index} />
          ))}
        </Carousel>
      </Container>
    </div>
  );
};

export default Index;
